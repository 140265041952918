import { Component, OnInit } from '@angular/core';
import { CookieService } from 'src/app/services/cookie.service';
@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss']
})
export class CookieBannerComponent implements OnInit {

  constructor(private cookiesService : CookieService) { }


  ngOnInit(): void {

  }
  decline(){
    this.cookiesService.declineCookie()
  }
  accept() {
    this.cookiesService.acceptCookie()
}
  }
