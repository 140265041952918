<app-navbar-one></app-navbar-one>

<!-- <div class="page-title-area item-bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Contact Us</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Contact Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->

<section class="contact-box pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-contact-box">
                    <i class="flaticon-pin"></i>
                    <div class="content-title">
                        <h3>Address</h3>
                        <p> 15 อาคารไอ-ทาวเวอร์ ชั้น15 888 วิภาวดี ถนนรังสิต จตุจักร จตุจักร กรุงเทพมหานคร</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-contact-box">
                    <i class="flaticon-envelope"></i>
                    <div class="content-title">
                        <h3>Email</h3>
                        <a href="mailto:contactus@alpacathailand.com">contactus@alpacathailand.com</a>
                        <!-- <a href="mailto:fax@avrax.com">fax@avrax.com</a> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-contact-box">
                    <i class="flaticon-phone-call"></i>
                    <div class="content-title">
                        <h3>Phone</h3>
                        <a href="tel:061416541">061-4165451</a>
                        <!-- <a href="tel:126446129"></a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="contact-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="contact-text">
                    <h3>แบบฟอร์มข้อมูล</h3>
                    <!-- <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed eiusmod tempor incididunt ut labore </p> -->
                </div>
                <div class="contact-form">
                    <form id="contactForm">
                        <div class="form-group">
                            <label>ชื่อเต็ม</label>
                            <input type="text" name="name" id="name" class="form-control" placeholder="ชื่อ-นามสกุล" [(ngModel)]="customer.name">
                        </div>
                        <div class="form-group">
                            <label> อีเมล์</label>
                            <input type="email" name="email" id="email" class="form-control" placeholder="อีเมลล์" [(ngModel)]="customer.email">
                        </div>
                        <div class="form-group">
                            <label>เบอร์ติดต่อ</label>
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="เบอร์ติดต่อ" [(ngModel)]="customer.contact">
                        </div>
                        <div class="form-group">
                            <label>หน่วยงาน</label>
                            <input type="text" name="company" id="company" class="form-control" placeholder="บริษัทxxx"[(ngModel)]="customer.company">
                        </div>
                        <div class="form-group">
                            <label>คำอธิบาย</label>
                            <textarea name="message" class="form-control" id="message" cols="30" rows="6" placeholder="คำอธิบาย" [(ngModel)]="customer.description"></textarea>
                        </div>
                        <div class="send-btn">
                            <button type="submit" class="default-btn" (click)="submit()">เริ่มต้นกับเรา</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-image">
                    <img src="assets/img/alpaca/contact.jpeg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>