import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import Swal from "sweetalert2";

interface Position {
    value: string;
}

@Component({
    selector: "app-job-aplication",
    templateUrl: "./job-application.component.html",
    styleUrls: ["./job-application.component.scss"],
})
export class JobApplicationComponent implements OnInit {
    submitForm: FormGroup;

    positions: Position[] = [];
    constructor(private fb: FormBuilder, private http: HttpClient) {}

    ngOnInit(): void {
        this.submitForm = this.fb.group({
            name: ["", Validators.required],
            surname: ["", Validators.required],
            age: ["", Validators.required],
            uni: ["", Validators.required],
            fac: ["", Validators.required],
            tel: ["", Validators.required],
            email: ["", Validators.required],
            pos: ["", Validators.required],
            about: ["", Validators.required],
            from: [""],
            resume: ["", Validators.required],
            port: [""],
            fb: ["", Validators.required],
            ig: [""],
        });

        this.getOpenPositions();
    }

    getOpenPositions() {
        this.http
            .get("https://alpacathailand.com/api/getOpenPosition.php")
            .subscribe((x: any) => {
                this.positions = x;
            });
    }

    submit() {
        console.log(this.submitForm.value);
        if (this.submitForm.valid) {
            this.http
                .post(
                    "https://alpacathailand.com/api/jobapplication.php",
                    this.submitForm.value
                )
                .subscribe((x) => {
                    console.log(x);
                    if (x == true) {
                        Swal.fire({
                            title: "บันทึกข้อมูลสําเร็จ",
                            text: "ขอบคุณที่สมัครงานกับเรา",
                            icon: "success",
                            confirmButtonText: "เสร็จสิ้น",
                            confirmButtonColor: "#0275d8",
                        });
                    } else {
                        Swal.fire({
                            title: "บันทึกข้อมูลผิดพลาด",
                            text: "กรุณาลองใหม่อีกครั้ง",
                            icon: "error",
                            confirmButtonText: "ลองใหม่อีกครั้ง",
                            confirmButtonColor: "#0275d8",
                        });
                    }
                });
        }
    }
}
