import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { GoogleTagManagerService } from "angular-google-tag-manager";

@Injectable({
    providedIn: "root",
})
export class CookieService {
    isAcceptCookies = false;
    isShowBanner = true;
    hasAcceptBefore = localStorage.getItem("cookieconsent");

    constructor(
        private router: Router,
        private gtmService: GoogleTagManagerService
    ) {}

    declineCookie() {
        this.isAcceptCookies = false;
        this.isShowBanner = false;
    }

    acceptCookie() {
        localStorage.setItem("cookieconsent","true")
        this.hasAcceptBefore = localStorage.getItem("cookieconsent");
        this.isAcceptCookies = true;
        this.isShowBanner = false;
        const gtmTag = {
            event: "page",
            isGranted: "true",
            pageName: this.router.url,
        };

        this.gtmService.addGtmToDom();
        this.gtmService.pushTag(gtmTag);
    }

    checkLocal(){
        return localStorage.getItem("cookieconsent")
    }
}
