<app-navbar-one></app-navbar-one>
<!-- SECTION1 -->
<div class="page-title-area item-bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{'ABOUT.SECTION1.HEADDER' | translate}}</h2>
                    <ul>
                        <li><a routerLink="/">{{'ABOUT.SECTION1.SUBHEADDER1' | translate}}</a></li>
                        <li>{{'ABOUT.SECTION1.SUBHEADDER2' | translate}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SECTION2 -->
<section class="about-section pt-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="about-image">
                    <img src="assets/img/alpaca/p6-2.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-tab">
                    <h2>ALPACA MEDIA AND TECHNOLOGY SOLUTION COMPANY LIMITED</h2>
                    <div class="bar"></div>
                    <p>เราคาดหวังที่จะเป็นผู้นำในด้าน Digital Technology เพื่อก้าวทันการเปลี่ยนแปลงในยุคของ Digital Disruption เราจึงมาพร้อมความมุ่งมั่นที่จะยกระดับการสื่อสารควบคู่ไปกับเทคโนยี เพื่อสร้างสรรค์สิ่งที่ดีที่สุด</p>
                    <div class="tab about-list-tab">
                        <ul class="tabs">
                            <li><a href="#">วิสัยทัศน์</a></li>
                            <li><a href="#">พันธกิจ</a></li>
                            <!-- <li><a href="#">Friendly Support</a></li> -->
                        </ul>
                        <div class="tab_content">
                            <div class="tabs_item">
                                <div class="text">
                                    <h3>transform Thailand</h3>
                                </div>
                                <ul class="list">
                                    <li><i class="flaticon-tick"></i> Tranfrom Thai People To Digital</li>
                                    <li><i class="flaticon-tick"></i> Tranform Business To Digital</li>
                                    <li><i class="flaticon-tick"></i> Tranform Our Mother Land to digital</li>
                                    <!-- <li><i class="flaticon-tick"></i> The Ultimate Deal on Business</li> -->
                                </ul>
                                <p>เพราะเป้าหมายเราคือการเปลี่ยนแปลงโลกยุคปัจจุบันให้ก้าวสู่โลกดิจิทัล โดยมีเป้าหมายเพื่อผลักดันไปสู่โลกที่ดีกว่า</p>
                                <a class="default-btn" routerLink="/contact">ติดต่อเรา</a>
                            </div>
                            <div class="tabs_item">
                                <div class="text">
                                    <h3>บริการครบวงจรเกี่ยวกับเทคโนโลยีดิจิตอล</h3>
                                </div>
                                <ul class="list">
                                    <li><i class="flaticon-tick"></i> Digital For Society</li>
                                    <li><i class="flaticon-tick"></i> Technology For People</li>
                                    <li><i class="flaticon-tick"></i> One Stop Service For Business</li>
                                    <li><i class="flaticon-tick"></i> การเป็นที่หนึ่งในเรื่องธุรกิจทางด้านดิจิทัลเทคโนโลยี เพื่อที่จะทำให้การบุกตลาดออนไลน์ของธุรกิจคุณพร้อมที่สุด</li>

                                </ul>
                                <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                                    gravida. Risus commodo viverra maecenas accumsan facilisis.</p> -->
                                <a class="default-btn" routerLink="/contact">ติดต่อเรา</a>
                            </div>
                            <!-- <div class="tabs_item">
                                <div class="text">
                                    <h3>How to generate your Creative Idea With IT Business</h3>
                                </div>
                                <ul class="list">
                                    <li><i class="flaticon-tick"></i> The Philosopy of Business Analytics</li>
                                    <li><i class="flaticon-tick"></i> Fast Track Your Business</li>
                                    <li><i class="flaticon-tick"></i> Lies & Damn Lies About Your Business</li>
                                    <li><i class="flaticon-tick"></i> The Ultimate Deal on Business</li>
                                </ul>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                                    gravida. Risus commodo viverra maecenas accumsan facilisis.</p>
                                <a class="default-btn" routerLink="/">Discover More</a>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="clients-section pt-100 pb-100">
    <div class="container">
        <div class="section-title">
            <h2>พนักงาน<span>กล่าวไว้</span>ว่า </h2>
            <p>เราฟังทุกเสียงของผู้เกี่ยวข้อง เพราะเราต้องเติบโตไปด้วยกัน</p>
            <div class="bar"></div>
        </div>
        <div class="clients-slider owl-carousel owl-theme">
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>ถึงแม้จะเป็นพนักงงาน ก็อยากเห็นบริษัทเติบโต เพราะเราคือครอบครัวเดียวกัน </p>
                <div class="clients-content">
                    <h3> จั๊น ชนากานต์ บูรณพรชัย</h3>
                    <span>Administrator</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>บะหมี่ ตั้งแต่มาที่นี่รู้สึกว่าการพัฒนาตนเองเป็นสิ่งที่สำคัญ เพราะที่นี่สอนให้พัฒนาตนเองเพื่อให้พร้อมที่สุด</p>
                <div class="clients-content">
                    <h3>บะหมี่ ปิยวัฒน์ ภิรมย์พิน</h3>
                    <span>Developer</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>บริษัทดี พนักงานดี รู้สึกผูกพันธ์กับที่นี่มาก ๆ</p>
                <div class="clients-content">
                    <h3>ข้าวปุ้น ปุณณัตถ์ สุวรรณบดินทร์</h3>
                    <span>Producer</span>
                </div>
            </div>
        </div>
    </div>
</section>
