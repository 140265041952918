import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-services-two',
  templateUrl: './services-two.component.html',
  styleUrls: ['./services-two.component.scss']
})
export class ServicesTwoComponent implements OnInit {

  constructor(private meta: Meta, private title: Title) { }

  ngOnInit(): void {
    this.title.setTitle("ซอฟต์แวร์และแอปพลิเคชัน | Alpaca Thailand")
  }

}
