<app-navbar-one></app-navbar-one>
<div class="main-banner-area-six">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <span>บริการของเรา</span>
                            <h1> การบริหารจัดการโซเซียลมีเดีย</h1>
                            <p>เรามุ่งมั่นที่จะเป็นสื่อไม่ใช่แค่ทำสื่อ</p>
                            <div class="banner-btn">
                                <a routerLink="/contact" class="default-btn">เริ่มกับเรา</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 pr-0">
                        <div class="banner-image">
                            <img src="assets/img/alpaca/services/services1/SM.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</div>


<section class="digital-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="digital-image">
                    <img src="assets/img/alpaca/s3-2.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="digital-content">
                    <h3> <span>การบริหารจัดการโซเซียลมีเดีย</span></h3>
                    <div class="bar"></div>
                    <p>บริหารจัดการแพลตฟอร์มต่างๆด้วยการวางแผนโดยการรวบรวมข้อมูล วิเคราะห์เทรนในแต่ละแพลตฟอร์ม เพื่อสร้างสรรค์เนื้อหาให้ตรงความต้องการของผู้ใช้งาน รวมไปถึงเก็บผลตอบรับจากลูกค้าเพื่อนำข้อมูลไปพัฒนาให้ตอบโจทย์มากขึ้น
                    </p>
                    <ul class="digital-list">
                        <li><i class="flaticon-check"></i> การบริหารจัดการเพจ</li>
                        <li><i class="flaticon-check"></i> ออกแบบกราฟฟิก</li>
                        <li><i class="flaticon-check"></i> คอนเทนต์</li>
                        <li><i class="flaticon-check"></i> การยิงแอดในโซเซียล</li>
                        <li><i class="flaticon-check"></i> การวางแผนมีเดีย</li>
                        <li><i class="flaticon-check"></i> วิดีโอโซเชียล</li>
                    </ul>
                    
                </div>
            </div>
        </div>
    </div>
</section>

<section class="tab-section pb-100">
    <div class="container">
        <div class="section-title">
            <h2>การบริหารจัดการ<span>จัดการ</span>โซเชียลมีเดีย</h2>
            <p>สังคมออนไลน์ที่เปลี่ยนไป ทำให้เกิดโอกาสที่จะเปลี่ยนแปลงธุรกิจคุณให้พุ่งทะยานบนโลกโซเชียลมีเดีย</p>
            <div class="bar"></div>
        </div>
        <div class="tab schedule-list-tab">
            <ul class="tabs">
                <li><a href="#"><span><i class="flaticon-analysis"></i> การบริหารจัดการช่องทางมีเดีย</span></a></li>
                <li><a href="#"><span><i class="flaticon-optimize"></i> กราฟฟิกดีไซน์</span></a></li>
                <li><a href="#"><span><i class="flaticon-software"></i> คอนเทนต์</span></a></li>
                <li><a href="#"><span><i class="flaticon-internet"></i> การลงโฆษณาบนโซเชียล</span></a></li>
                <li><a href="#"><span><i class="flaticon-data"></i> การวางแผนบนมีเดีย</span></a></li>
                <!-- <li><a href="#"><span><i class="flaticon-data"></i> วีดีโอออนไลน์</span></a></li> -->
            </ul>
            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-content">
                                <h2>การบริหารจัดการช่องทางมีเดีย</h2>
                                <p>ธุรกิจของคุณควรได้รับการดูแด้วยทีมงานผู้เชี่ยวชาญด้านตลาดบนโซเซียลมีเดีย มาบริหารสื่อสังคมออนไลน์</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>เฟสบุ๊ค</h3>
                                    <p>ดูแลเพจเฟสบุ๊คให้กับคุณได้อย่างมีประสิทธิภาพ</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ไลน์ออฟฟิศเซียล</h3>
                                    <p>ดูแลไลน์ออฟฟิศเซียลให้กับคุณได้อย่างมีประสิทธิภาพ</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ผู้ดูแลแพลตฟอร์ม</h3>
                                    <p>ดูแลทุกแพลตฟอร์มให้กับคุณได้ เพียงแค่ติดต่อเรา</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/alpaca/s3-4.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-content">
                                <h2>ออกแบบกราฟฟิก</h2>
                                <p>หากคุณต้องการปรับปรุงแบรนด์ของคุณด้วยการออกแบบกราฟิก ติดต่อนักออกแบบผู้เชี่ยวชาญของเราวันนี้</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>สร้างสรรค์</h3>
                                    <p>สามารถออกแบบกราฟิกที่สร้างสรรค์และตรงใจคุณลูกค้าได้</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>รวดเร็วทันใจ</h3>
                                    <p>ด้วยความชำนาญของทีมงานเราสามารถออกแบบได้อย่างรวดเร็วทันใจ</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ความหลากหลาย</h3>
                                    <p>สามารถรังสรรค์ผลงานได้อย่างหลากหลายเพื่อหารูปแบบที่ตรงใจคุณลูกค้ามากที่สุด</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/alpaca/s3-3.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-content">
                                <h2>คอนเทนต์</h2>
                                <p>หากคุณจะทำโฆษณาออนไลน์ ทำเพจบนโซเชียลมีเดีย เขียนบล็อก ทำเว็บไซต์ ประชาสัมพันธ์ ขายสินค้าหรือบริการล้วนแต่ต้องเขียนคอนเทนต์ให้เหมาะสมและเข้าถึงกลุ่มเป้าหมาย</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ทีมผู้เชี่ยวชาญ</h3>
                                    <p>เรามีทีมเชียวชาญที่สามารถสร้างสรรค์คอนเทนต์ที่มีความเกี่ยวเนื่องกับสินค้าหรือบริการของคุณ</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>คอนเทนต์คุณภาพ</h3>
                                    <p>เราจัดทำคอนเทนต์ที่มีคุณภาพเพื่อคุณ</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ตรงประเด็น</h3>
                                    <p>สร้างสรรค์ได้อย่างตรงประเด็นและตรงใจ</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/alpaca/s3-5.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-content">
                                <h2>การยิงแอดในโซเซียล</h2>
                                <p>กลยุทธ์หนึ่งที่จะช่วยให้ลูกเค้าเข้าถึงแบรนด์คุณได้มากยิ่งขึ้น คือการยิงแอดจากผู้เชี่ยวชาญ</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ยิงแอดเฟสบุ๊ค</h3>
                                    <p>เราสามารถยิงโฆษณาผ่านช่องทางเฟสบุ๊คให้กับคุณ</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ยิงแอดกลูเกิ้ล</h3>
                                    <p>เราสามารถยิงโฆษณาผ่านชกลูเกิ้ลให้กับคุณ</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ยิงแอดแบรนด์คอนเทนต์</h3>
                                    <p>เราสามารถสร้างสรรค์แบรนด์คอนเทนท์ที่มีคุณภาพและยิงไปยังเเพลตฟอร์มต่างๆ</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/alpaca/s3-3.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="tab-content">
                                <h2>การวางแผนมีเดีย</h2>
                                <p>การวางแผนมีเดียที่ดี คือสามารถวางแผนที่จะทำการสื่อสารไปยังกลุ่มเป้าหมายเหมาะสมกับบริษัทของคุณ</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>เชี่ยวชาญ</h3>
                                    <p>ทางทีมงานเรามีความเชี่ยวชาญสามารถวางแผนมีเดียได้อย่างตรงจุด</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>เครื่องมือ</h3>
                                    <p>เราสามารถใช้เครื่องมือที่มีสามารถวางแผนได้มีประสิทธิภาพ</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ประสบการณ์</h3>
                                    <p>มีประสบการณ์ที่สามารถแก้ไขปัญหาต่างๆได้อย่างทันท่วงที</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/alpaca/s3-6.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="pricing-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2> <span>แพคเกจราคาของเรา</span> </h2>
            <p>แพ็คเกจเริ่มต้นสำหรับการดูแลเพจ เพื่อให้เพจคุณมีตัวตนบนโลกออนไลน์</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-pricing">
                    <div class="pricing-header">
                        <h3>Silver</h3>
                        <p>Business Up</p>
                    </div>
                    <div class="price">
                        <sup>$</sup>29<sub>/mo</sub>
                    </div>
                    <ul class="pricing-list">
                        <li><i class="flaticon-checked"></i> 10 GB Hosting </li>
                        <li><i class="flaticon-checked"></i> 2 Unique Users </li>
                        <li><i class="flaticon-checked"></i> 12 GB Capacity</li>
                        <li><i class="flaticon-cancel"></i> Any Where Access</li>
                        <li><i class="flaticon-cancel"></i> Weekly Backup</li>
                        <li><i class="flaticon-cancel"></i> Support 24/Hour</li>
                    </ul>
                    <div class="price-btn">
                        <a routerLink="/" class="default-btn">Buy Now</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-pricing">
                    <div class="pricing-header">
                        <h3>Gold</h3>
                        <p>Business Up</p>
                    </div>
                    <div class="price">
                        <sup>$</sup>79<sub>/mo</sub>
                    </div>
                    <ul class="pricing-list">
                        <li><i class="flaticon-checked"></i> Visitor Info</li>
                        <li><i class="flaticon-checked"></i> Quick Responses</li>
                        <li><i class="flaticon-checked"></i> 12 GB Capacity</li>
                        <li><i class="flaticon-checked"></i> Any Where Access</li>
                        <li><i class="flaticon-cancel"></i> Weekly Backup</li>
                        <li><i class="flaticon-cancel"></i> Support 24/Hour</li>
                    </ul>
                    <div class="price-btn">
                        <a routerLink="/" class="default-btn">Buy Now</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-pricing">
                    <div class="pricing-header">
                        <h3>Platinum</h3>
                        <p>Business Up</p>
                    </div>
                    <div class="price">
                        <sup>$</sup>99<sub>/mo</sub>
                    </div>
                    <ul class="pricing-list">
                        <li><i class="flaticon-checked"></i> 10 GB Hosting </li>
                        <li><i class="flaticon-checked"></i> 2 Unique Users </li>
                        <li><i class="flaticon-checked"></i> 12 GB Capacity</li>
                        <li><i class="flaticon-checked"></i> Any Where Access</li>
                        <li><i class="flaticon-checked"></i> Weekly Backup</li>
                        <li><i class="flaticon-cancel"></i> Support 24/Hour</li>
                    </ul>
                    <div class="price-btn">
                        <a routerLink="/" class="default-btn">Buy Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section> -->