<app-navbar-one></app-navbar-one>
<head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
</head>


<div class="detail-title-area" style=" background-image: url({{currentProject.picA}})">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{currentProject.name}}</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/projects">Projects Details</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>



<section class="pic-container">

    <div *ngIf="currentProject.isVideo" class="fluid-container m-5">
        <div class="row justify-content-center">
            <!-- <video width="1200" type="video/mp4" controls class="justify-self-center">
                <source src="{{currentProject.picB}}">
            </video> -->
            <iframe width="1280" height="720" src="https://www.youtube.com/embed/EYuuAFzx9dQ" frameborder="0" allowfullscreen></iframe>
        </div>
    </div>
    <div *ngIf="!currentProject.isVideo" class= "fluid-container m-5">
        <div class="row justify-content-center">
            <div class=" col-lg">
                <img src = {{currentProject.picB}} alt="image">
            </div>
            <div class = " col-lg">
                <img src = {{currentProject.picC}} alt="image">
            </div>
        </div>
    </div>
    <div style="margin: 3.5%;">
        <h4 class="font-weight-bold" style="line-height: 2;"> เกี่ยวกับผลงาน </h4>
        <p> {{currentProject.con1}}</p>
        <h4 style="line-height: 2;"> องค์กรของเราได้มีส่วนร่วมในความสําเร็จ...</h4>
        <p> {{currentProject.con2}}</p>

        <h4 style="line-height: 2;" *ngIf="!(currentProject.con3 === '' && currentProject.con4 === '' && currentProject.con5 === '')"> ดูเพิ่มเติมได้ที่ </h4>

        <span *ngIf="currentProject.con3">
            <a class="m-1" href={{currentProject.con3}}>

                <i class="fa fa-facebook-square fa-2x"  aria-hidden="true"></i>
            </a>​
            <!-- <a class="m-1" ng-if="currentProject.con3 == '' " >
                <button> Youtube</button>
                <i class="fa fa-facebook-square fa-2x"  aria-hidden="true"></i>
            </a> -->

        </span>
        <span class="m-1" *ngIf="currentProject.con4">
            <a class="" href={{currentProject.con4}}>

                <i class="fa fa-youtube-square fa-2x"  aria-hidden="true"></i>
            </a>
        </span>
        <span class="m-1" *ngIf="currentProject.con5">
            <a class="" href={{currentProject.con5}}>

                <i class="fa fa-chrome fa-2x" aria-hidden="true"></i>
            </a>
        </span>
    </div>



</section>



