<app-navbar-one></app-navbar-one>
<div class="main-banner-area-six">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <span>บริการของเรา</span>
                            <h1>การออกแบบเว็บไซต์</h1>
                            <p>การดีไซน์เว็บที่สามารถใช้ได้ทุกแพลตฟอร์ม เป็นหลักสำคัญในการโปรโมทเว็บไซต์</p>
                            <div class="banner-btn">
                                <a routerLink="/contact" class="default-btn">เริ่มต้นกับเรา</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 pr-0">
                        <div class="banner-image">
                            <img src="assets/img/alpaca/services/services1/web.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</div>


<section class="digital-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="digital-image">
                    <img src="assets/img/alpaca/s5-2.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="digital-content">
                    <h3> <span>การออกแบบเว็บไซต์</span></h3>
                    <div class="bar"></div>
                    <p>การพัฒนาเว็ปไซต์ของคุณให้มั่นคง ด้วยการจัดการระบบหลังบ้านอย่างมีประสิทธิภาพ รวมถึงระบบตรวจสอบสถิติคนเข้าชมเว็ปไซต์ เพื่อรองรับสินค้าและธุรกิจของคุณ เพื่อเพิ่มความน่าเชื่อถือให้ธุรกิจของคุณดูเป็นมืออาชีพมากขึ้น</p>
                    <ul class="digital-list">
                        <li><i class="flaticon-check"></i> Sale Page</li>
                        <li><i class="flaticon-check"></i> One Page</li>
                        <li><i class="flaticon-check"></i> Cooperate Website</li>
                        <li><i class="flaticon-check"></i> E-commerce</li>
                        <li><i class="flaticon-check"></i> Web app</li>
                        <li><i class="flaticon-check"></i> SEO</li>
                    </ul>
                    
                </div>
            </div>
        </div>
    </div>
</section>

<section class="tab-section pb-100">
    <div class="container">
        <div class="section-title">
            <h2>การบริการของเรา เกี่ยวกับการออกแบบและบริการเว็บไซต์ <span></span> </h2>
            <p>พัฒนาเว็บไซต์เพื่อเพิ่มความน่าเชื่อถือให้ธุรกิจของคุณ</p>
            <div class="bar"></div>
        </div>
        <div class="tab schedule-list-tab">
            <ul class="tabs">
                <li><a href="#"><span><i class="flaticon-analysis"></i> Sale Page Website</span></a></li>
                <li><a href="#"><span><i class="flaticon-optimize"></i> One Page Website</span></a></li>
                <li><a href="#"><span><i class="flaticon-software"></i> E-commerce Website</span></a></li>
                <li><a href="#"><span><i class="flaticon-internet"></i> Software application</span></a></li>
                <li><a href="#"><span><i class="flaticon-data"></i> SEO</span></a></li>
            </ul>
            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-content">
                                <h2>Sale Page Website</h2>
                                <p>เราสามารถจัดทำแสดงรายละเอียดที่น่าสนใจของสินค้าเพื่อคุณ</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>หน้าเดียวจบ</h3>
                                    <p>เราสามารถรวมรวบสินค้าแต่ละตัวให้ผู้บริโภคได้เห็นสินค้าทั้งหมด รวมไปถึงมีข้อมูลต่าง ๆ ไม่ว่าจะเป็น รูปภาพ วีดีโอ  เป็นต้น</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ง่ายและรวดเร็ว</h3>
                                    <p>สะดวกต่อการใช้งานของลูกค้า ทำให้ผู้บริโภคเข้าถึงสินค้าของคุณได้ง่ายขึ้น</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ราคาถูก</h3>
                                    <p>ต้นทุนน้อยก็สามารถสร้าง Sale Page Website ได้เพียงแค่ติดต่อเรา
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/alpaca/web/spw.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-content">
                                <h2>One-Page-Website</h2>
                                <p>เราสามารถออกแบบเว็บไซต์ได้ตามรูปเเบบที่คุณต้องการ</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>หน้าเดียวจบ</h3>
                                    <p>รวมรวบสินค้าแต่ละตัวให้ผู้บริโภคได้เห็นสินค้าทั้งหมด รวมไปถึงมีข้อมูลต่าง ๆ ไม่ว่าจะเป็น รูปภาพ วีดีโอ  เป็นต้น</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>น้อยแต่มาก</h3>
                                    <p>การวางแผนการใช้เว็บไซต์ที่สะดวก ไม่ต้องกดเข้าหลายหน้า หน้าเดียวจบ</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>เข้าใจง่าย</h3>
                                    <p>สะดวกต่อการใช้งานของลูกค้า ทำให้ผู้บริโภคเข้าถึงสินค้าและสามารถเปรียบเทียบสินค้าของคุณได้ง่ายขึ้น</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/alpaca/web/opw.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-content">
                                <h2>E-Commerce Website</h2>
                                <p>ทางเรามีการจัด E commerce Website ทำสำหรับแสดงรายการและรายละเอียดที่น่าสนใจของสินค้า รวมไปถึงสามารถแลกเปลี่ยนสินค้าและบริการผ่านเว็บไซต์ได้โดยตรง</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ระบบขายสินค้า</h3>
                                    <p>มีการจัดทำระบบการขายที่มีประสิทธิภาพ ง่ายต่อการตวรจสอบ</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>การจ่ายเงินออนไลน์</h3>
                                    <p>เรามีการจัดระบบให้ สามารถทำธุรกรรมได้ผ่านเว็บไซต์ช่วยให้ประหยัดเวลามากขึ้น</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ช่องทางใหม่</h3>
                                    <p>E-commerce Website เป็นช่องทางใหม่ที่จะทำให้คุณสามารถขายสินค้าและบริการของคุณง่ายขึ้น
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/alpaca/web/ecw.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-content">
                                <h2>Software Application</h2>
                                <p>แอปพลิเคชั่นเป็นส่วนสำคัญในขยายขนาดธุรกิจของคุณ เริ่มต้นทำแอปพลิเคชั่นกับเรา</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ระบบการจัดการ</h3>
                                    <p>ทางเรามีการจัดทำระบบการจัดการแอฟพลิเคชั่นให้กับคุณ</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>เข้าถึงได้ง่าย</h3>
                                    <p>ทำให้ผู้บริโภคง่ายต่อการเข้าถึงสินค้าและบริการของคุณ</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>หนึ่งเดียวจบ</h3>
                                    <p>รวมรวบทุกอย่างจบเพียงแอปเดียว</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/alpaca/web/cw.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="tab-content">
                                <h2>Search Engine Optimization</h2>
                                <p>เป็นการทำเว็บไซต์ให้มีประสิทธิภาพและรองรับการติดอันดับบน google</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>สร้างการค้นหา</h3>
                                    <p>ช่วยให้เว็บไซต์ถูกค้นหาได้ง่ายจากผู้คนที่สนใจให้สินค้าหรือบริการของคุณ</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>Keyword ที่สำคัญ</h3>
                                    <p>เราจำแนกคีย์เวิร์ดที่สำคัญ ทำให้คุณลูกค้าสามารถค้นหาได้ง่ายขึ้น</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ขึ้นท็อป</h3>
                                    <p>รองรับการติดอันดับบน google ให้ลูกค้า ค้นหาได้ง่ายมากขึ้น
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/alpaca/web/seo.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="pricing-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Pricing</span> Plan</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-pricing">
                    <div class="pricing-header">
                        <h3>Basic</h3>
                        <p>Business Up</p>
                    </div>
                    <div class="price">
                        <sup>$</sup>29<sub>/mo</sub>
                    </div>
                    <ul class="pricing-list">
                        <li><i class="flaticon-checked"></i> 10 GB Hosting </li>
                        <li><i class="flaticon-checked"></i> 2 Unique Users </li>
                        <li><i class="flaticon-checked"></i> 12 GB Capacity</li>
                        <li><i class="flaticon-cancel"></i> Any Where Access</li>
                        <li><i class="flaticon-cancel"></i> Weekly Backup</li>
                        <li><i class="flaticon-cancel"></i> Support 24/Hour</li>
                    </ul>
                    <div class="price-btn">
                        <a routerLink="/" class="default-btn">Buy Now</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-pricing">
                    <div class="pricing-header">
                        <h3>Standard</h3>
                        <p>Business Up</p>
                    </div>
                    <div class="price">
                        <sup>$</sup>79<sub>/mo</sub>
                    </div>
                    <ul class="pricing-list">
                        <li><i class="flaticon-checked"></i> Visitor Info</li>
                        <li><i class="flaticon-checked"></i> Quick Responses</li>
                        <li><i class="flaticon-checked"></i> 12 GB Capacity</li>
                        <li><i class="flaticon-checked"></i> Any Where Access</li>
                        <li><i class="flaticon-cancel"></i> Weekly Backup</li>
                        <li><i class="flaticon-cancel"></i> Support 24/Hour</li>
                    </ul>
                    <div class="price-btn">
                        <a routerLink="/" class="default-btn">Buy Now</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-pricing">
                    <div class="pricing-header">
                        <h3>Premium</h3>
                        <p>Business Up</p>
                    </div>
                    <div class="price">
                        <sup>$</sup>99<sub>/mo</sub>
                    </div>
                    <ul class="pricing-list">
                        <li><i class="flaticon-checked"></i> 10 GB Hosting </li>
                        <li><i class="flaticon-checked"></i> 2 Unique Users </li>
                        <li><i class="flaticon-checked"></i> 12 GB Capacity</li>
                        <li><i class="flaticon-checked"></i> Any Where Access</li>
                        <li><i class="flaticon-checked"></i> Weekly Backup</li>
                        <li><i class="flaticon-cancel"></i> Support 24/Hour</li>
                    </ul>
                    <div class="price-btn">
                        <a routerLink="/" class="default-btn">Buy Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section> -->