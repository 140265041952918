<app-navbar-one></app-navbar-one>

<div class="main-banner-area-three">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <span>งานของเรา</span>
                            <h1>ประวัติผลงาน</h1>
                            <p>จุดมุ่งหมายของเรา ต้องการสร้างความสำเร็จให้กับคุณ และนั้นคือความสำเร็จของเรา</p>
                            <div class="banner-btn">
                                <a routerLink="/contact" class="default-btn">เริ่มกับเรา</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="tech-image">
                            <img src="assets/img/alpaca/services/services1/hs.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="projects-section pt-100 pb-70">
    <div class="container-fluid">

        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/alpaca/publisher.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>The Publisher</h3>
                    </div>
                    <a target="_blank" routerLink="/project-description/publisher" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/alpaca/guru.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>กูรูวัยเก๋า</h3>
                    </div>
                    <a target="_blank" routerLink="/project-description/guru" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/alpaca/digi.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>Digital Alert</h3>
                    </div>
                    <a target="_blank" routerLink="/project-description/digi" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">

                <div class="single-projects-box" >
                    <div class="projects-image" >
                        <img src="assets/img/alpaca/savethai.png" alt="image" >
                    </div>
                    <div class="projects-content">
                        <h3>Save Thai Fight Covid</h3>
                    </div>
                    <a target="_blank" routerLink="/charity" class="link-btn"></a>
                </div>
            </div>
            <!-- small pic -->
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img  src="assets/img/alpaca/move.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>Move Application</h3>
                    </div>
                    <a target="_blank" routerLink="/project-description/move" class="link-btn"></a>
                </div>
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img style="height: 300px;" src="assets/img/alpaca/rbs.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>RBS Group</h3>
                    </div>
                    <a target="_blank" routerLink="/project-description/rbs" class="link-btn"></a>
                </div>

            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/alpaca/seed.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>SEED</h3>
                    </div>
                    <a target="_blank" routerLink="/project-description/seed" class="link-btn"></a>
                </div>
            </div>

            <!-- new content -->
            <div class="col-md col-sm-12">
                <div class="single-projects-box">
                    <div class="projects-image1">
                        <img src="assets/img/alpaca/youth.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>Youth Leader New Gen</h3>
                    </div>
                    <a target="_blank" routerLink="/project-description/ylng" class="link-btn"></a>

                </div>
            </div>
            <div class="col-md col-sm-12">
                <div class="single-projects-box">
                    <div class="projects-image1">
                        <img src="assets/img/alpaca/huahin.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>หัวหิน บ้านของพ่อ</h3>
                    </div>
                    <a target="_blank" routerLink="/project-description/huahin" class="link-btn"></a>
                </div>
            </div>
            <!--
            <div class="col-md-3 col-sm-12">
                <div class="single-projects-box">
                    <div class="projects-image1">
                        <img src="assets/img/alpaca/10.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>ต้องรอด</h3>
                    </div>
                    <a target="_blank" routerLink="/project-description/sur" class="link-btn"></a>
                </div>
            </div> -->

            <div class="col-md col-sm-12">
                <div class="single-projects-box">
                    <div class="projects-image1">
                        <img src="assets/img/alpaca/mhuahin.png" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>คิดถึงหัวหิน</h3>
                    </div>
                    <a target="_blank" routerLink="/project-description/misshuahin" class="link-btn"></a>
                </div>
            </div>
        </div>
    </div>
</section>
