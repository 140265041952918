<app-navbar-one></app-navbar-one>
<!-- 
<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Sign Up</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Sign Up</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="signup-section ptb-100">
    <div class="container">
        <div class="signup-form">
            <h3>ร่วมงานกับเรา</h3>
            <img
                src="assets/img/alpaca/logo.png"
                class="job-app-logo"
                alt="jobapp-logo"
            />
            <form [formGroup]="submitForm">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <h5 class="required">ชื่อ</h5>
                            <input
                                type="text"
                                id="fname"
                                class="form-control"
                                placeholder=""
                                formControlName="name"
                            />
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <h5 class="required">นามสกุล</h5>
                            <input
                                type="text"
                                id="fname"
                                class="form-control"
                                placeholder=""
                                formControlName="surname"
                            />
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <h5 class="required">อายุ</h5>
                            <input
                                type="text"
                                id="age"
                                class="form-control"
                                placeholder=""
                                formControlName="age"
                            />
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <h5 class="required">มหาวิทยาลัย</h5>
                            <input
                                type="text"
                                id="university"
                                class="form-control"
                                placeholder=""
                                formControlName="uni"
                            />
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <h5 class="required">คณะ</h5>
                            <input
                                type="text"
                                id="faculty"
                                class="form-control"
                                placeholder=""
                                formControlName="fac"
                            />
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <h5 class="required">เบอร์โทรศัพท์ติดต่อกลับ</h5>
                            <input
                                type="tel"
                                id="telephone"
                                class="form-control"
                                placeholder=""
                                formControlName="tel"
                            />
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <h5 class="required">Email</h5>
                            <input
                                type="email"
                                id="email"
                                class="form-control"
                                placeholder=""
                                formControlName="email"
                            />
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <h5>ตําแหน่งที่สนใจสมัคร</h5>
                            <select
                                id="job-position"
                                name="job-position"
                                formControlName="pos"
                            >
                                <option [value]=""></option>
                                <option
                                    *ngFor="let pos of positions"
                                    [value]="pos.value"
                                >
                                    {{ pos.value }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <h5 class="required">แนะนําตัวเองเบื้องต้น</h5>
                            <input
                                type="text"
                                id="about"
                                class="form-control"
                                placeholder=""
                                formControlName="about"
                            />
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <h5>ได้รับข่าวสารรับสมัครจากช่องทางใด</h5>
                            <input
                                type="text"
                                id="from"
                                class="form-control"
                                placeholder=""
                                formControlName="from"
                            />
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <h5 class="required">Resume / Cv (ลิ้งค์)</h5>
                            <input
                                type="url"
                                id="resume"
                                class="form-control"
                                placeholder=""
                                formControlName="resume"
                            />
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <h5>Portfolio (ลิ้งค์)</h5>
                            <input
                                type="url"
                                id="portfolio"
                                class="form-control"
                                placeholder=""
                                formControlName="port"
                            />
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <h5 class="required">Facebook</h5>
                            <input
                                type="text"
                                id="fb"
                                class="form-control"
                                placeholder=""
                                formControlName="fb"
                            />
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <h5>Instragram</h5>
                            <input
                                type="text"
                                id="ig"
                                class="form-control"
                                placeholder=""
                                formControlName="ig"
                            />
                        </div>
                    </div>
                    <!-- <div class="col-lg-12">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="checkme">
                            <label class="form-check-label" for="checkme">Keep Me Sign Up</label>
                        </div>
                    </div> -->
                    <div class="col-lg-12">
                        <div class="send-btn">
                            <button
                                class="btn btn-primary"
                                (click)="submit()"
                                [disabled]="!submitForm.valid"
                            >
                                สมัครงาน
                            </button>
                            <!-- <a routerLink="/" class="default-btn">สมัครงาน</a> -->
                        </div>
 
                        <br />
                        <!-- <span>Already a registered user? <a routerLink="/log-in">Login!</a></span> -->
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/4.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/5.svg" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/6.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/7.png" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/8.png" alt="image" />
        </div>
    </div>
</div>
