import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { Ng9OdometerModule } from "ng9-odometer";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PreloaderComponent } from "./components/layouts/preloader/preloader.component";
import { FooterComponent } from "./components/layouts/footer/footer.component";
import { HomeOneComponent } from "./components/pages/home-one/home-one.component";
import { NavbarOneComponent } from "./components/layouts/navbar-one/navbar-one.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { ServicesOneComponent } from "./components/pages/services-one/services-one.component";
import { ServicesDetailsComponent } from "./components/pages/services-details/services-details.component";
import { ProjectsOneComponent } from "./components/pages/projects-one/projects-one.component";
import { ProjectsDetailsComponent } from "./components/pages/projects-details/projects-details.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { BlogRightSidebarComponent } from "./components/pages/blog-right-sidebar/blog-right-sidebar.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { JobApplicationComponent } from "./components/pages/job-application/job-application.component";
// import { ContactComponent } from './components/pages/contact/contact.component';
import { ServicesTwoComponent } from "./components/pages/services-two/services-two.component";
import { ServicesThreeComponent } from "./components/pages/services-three/services-three.component";
import { ServicesFourComponent } from "./components/pages/services-four/services-four.component";
import { ServicesFiveComponent } from "./components/pages/services-five/services-five.component";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ServicesSixComponent } from "./components/pages/services-six/services-six.component";
import { AdminComponent } from "./components/pages/admin/admin.component";
import { FormBuilder, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatTableModule } from "@angular/material/table";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { TraineeJobAppComponent } from "./components/pages/trainee-job-app/trainee-job-app.component";
import { CookieBannerComponent } from "./common/cookie-banner/cookie-banner.component";
import { GoogleTagManagerModule } from "angular-google-tag-manager";
import { ProjectDescriptionComponent } from './components/pages/project-description/project-description.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        FooterComponent,
        HomeOneComponent,
        NavbarOneComponent,
        AboutComponent,
        ServicesOneComponent,
        ServicesDetailsComponent,
        ProjectsOneComponent,
        ProjectsDetailsComponent,
        ContactComponent,
        BlogRightSidebarComponent,
        BlogDetailsComponent,
        ServicesTwoComponent,
        ServicesThreeComponent,
        ServicesFourComponent,
        ServicesFiveComponent,
        ServicesSixComponent,
        AdminComponent,
        JobApplicationComponent,
        TraineeJobAppComponent,
        CookieBannerComponent,
        ProjectDescriptionComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: "serverApp" }),
        AppRoutingModule,
        HttpClientModule,
        Ng9OdometerModule.forRoot(),
        TranslateModule.forRoot({
            defaultLanguage: "th",
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatTableModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        GoogleTagManagerModule.forRoot({
            id: "GTM-NWZG447",
        }),
    ],

    providers: [{ provide: "googleTagManagerId", useValue: "GTM-NWZG447" }],
    bootstrap: [AppComponent],
})
export class AppModule {}
