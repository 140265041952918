import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import Swal from "sweetalert2";

interface Position {
  value: string;
}

@Component({
  selector: 'app-trainee-job-app',
  templateUrl: './trainee-job-app.component.html',
  styleUrls: ['./trainee-job-app.component.scss']
})


export class TraineeJobAppComponent implements OnInit {

  submitForm: FormGroup;

    traineePositions: Position[] = [];
    constructor(private fb: FormBuilder, private http: HttpClient) {}
  ngOnInit(): void {
    this.submitForm = this.fb.group({
      name: ["", Validators.required],
      surname: ["", Validators.required],
      age: ["", Validators.required],
      uni: ["", Validators.required],
      fac: ["", Validators.required],
      tel: ["", Validators.required],
      email: ["", Validators.required],
      pos: ["", Validators.required],
      date: ["", Validators.required],
      period: ["", Validators.required],
      about: ["", Validators.required],
      from: ["", Validators.required],
      resume: ["", Validators.required],
      port: ["", Validators.required],
      fb: ["", Validators.required],
      ig: [""],
  });

  this.getTraineeOpenPositions(); 
  }


  getTraineeOpenPositions() {
    this.http
        .get("https://alpacathailand.com/api/getTraineeOpenPosition.php")
        .subscribe((x: any) => {
            this.traineePositions = x; 
        });
}

  submit() {
    console.log(this.submitForm.value);
    if (this.submitForm.valid) {
        this.http
            .post(
                "https://alpacathailand.com/api/jobapplication.php",
                this.submitForm.value
            )
            .subscribe((x) => {
                console.log(x);
                if (x == true) {
                    Swal.fire({
                        title: "บันทึกข้อมูลสําเร็จ",
                        text: "ขอบคุณที่สมัครงานกับเรา",
                        icon: "success",
                        confirmButtonText: "เสร็จสิ้น",
                        confirmButtonColor: "#0275d8",
                    });
                } else {
                    Swal.fire({
                        title: "บันทึกข้อมูลผิดพลาด",
                        text: "กรุณาลองใหม่อีกครั้ง",
                        icon: "error",
                        confirmButtonText: "ลองใหม่อีกครั้ง",
                        confirmButtonColor: "#0275d8",
                    });
                }
            });
    }
}



}
