<div *ngIf="!isLogin">
<mat-form-field appearance="outline">
    <mat-label>Enter your password</mat-label>
    <input matInput [type]="'password'" [(ngModel)]="input">
</mat-form-field>
<button mat-raised-button color="primary" (click)="login()">Login</button>
</div>

<div *ngIf="isLogin">
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="contact">
        <th mat-header-cell *matHeaderCellDef> Contact </th>
        <td mat-cell *matCellDef="let element"> {{element.contact}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Description </th>
        <td mat-cell *matCellDef="let element"> {{element.description}} </td>
    </ng-container>

    <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef> Company </th>
        <td mat-cell *matCellDef="let element"> {{element.company}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
</div>