import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {


  constructor(private meta: Meta, private title: Title) { }

  ngOnInit(): void {
    this.title.setTitle("Alpaca Media and Technology Solutions")
  }

}
