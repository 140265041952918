import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ProjectsDetailsComponent } from './components/pages/projects-details/projects-details.component';
import { ProjectsOneComponent } from './components/pages/projects-one/projects-one.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { ServicesOneComponent } from './components/pages/services-one/services-one.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ServicesTwoComponent } from './components/pages/services-two/services-two.component';
import { ServicesThreeComponent } from './components/pages/services-three/services-three.component';
import { ServicesFourComponent } from './components/pages/services-four/services-four.component';
import { ServicesFiveComponent } from './components/pages/services-five/services-five.component';
import { ServicesSixComponent } from './components/pages/services-six/services-six.component';
import { AdminComponent } from './components/pages/admin/admin.component';
import { JobApplicationComponent } from './components/pages/job-application/job-application.component';
import { ProjectDescriptionComponent } from './components/pages/project-description/project-description.component';
import { TraineeJobAppComponent } from './components/pages/trainee-job-app/trainee-job-app.component';

const routes: Routes = [
    {path: '', component: HomeOneComponent},
    {path: 'about', component: AboutComponent},
    {path: 'consult', component: ServicesOneComponent},
    {path: 'services', component: ServicesDetailsComponent},
    {path: 'projects', component: ProjectsOneComponent},
    {path: 'charity', component: ProjectsDetailsComponent},
    {path: 'contact',component: ContactComponent},
    {path: 'blog-right-sidebar', component: BlogRightSidebarComponent},
    {path: 'blog-details', component: BlogDetailsComponent},
    {path: 'software', component: ServicesTwoComponent},
    {path: 'socialmedia', component: ServicesThreeComponent},
    {path: 'production', component: ServicesFourComponent},
    {path: 'websitedesign', component: ServicesFiveComponent},
    {path: 'lineoa', component: ServicesSixComponent},
    {path: 'admin', component: AdminComponent},
    {path: 'job-application', component: JobApplicationComponent},
    {path: 'project-description/:slug', component: ProjectDescriptionComponent},
    {path: 'trainee-job-app', component: TraineeJobAppComponent}

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
