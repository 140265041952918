<app-navbar-one></app-navbar-one>
<div class="main-banner-area-six">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <span>การบริการของเรา</span>
                            <h1>ซอฟต์แวร์และแอปพลิเคชัน</h1>
                            <p>การก้าวหน้าเทคโนโลยีตั้งอยู่บนพื้นฐานของความกลมกลืน เป็นส่วนหนึ่งของกิจวัตรประจำวันของเรา</p>
                            <div class="banner-btn">
                                <a routerLink="/contact" class="default-btn">เริ่มกับเรา</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 pr-0">
                        <div class="banner-image">
                            <img src="assets/img/alpaca/services/services1/SW.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</div>


<section class="digital-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="digital-image">
                    <img src="assets/img/alpaca/p4-1.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="digital-content">
                    <h3> <span>ซอฟต์แวร์และแอปพลิเคชัน</span></h3>
                    <div class="bar"></div>
                    <p>การพัฒนาระบบซอฟต์แวร์และแอปพลิเคชัน สามารถเพิ่มความน่าเชื่อถือให้กับธุรกิจของคุณ</p>
                    <ul class="digital-list">
                        <li><i class="flaticon-check"></i> เว็บไซต์แอปพลิเคชัน</li>
                        <!-- <li><i class="flaticon-check"></i> ผู้ปรึกษาที่เชี่ยวชาญ</li> -->
                        <!-- <li><i class="flaticon-check"></i> Youtube Marketing</li>
                        <li><i class="flaticon-check"></i> Social Marketing</li>
                        <li><i class="flaticon-check"></i> Facebook Marketing</li>
                        <li><i class="flaticon-check"></i> Page Ranking</li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="tab-section pb-100">
    <div class="container">
        <div class="section-title">
            <h2>แอปพลิเคชัน <span>เพื่อคุณ</span> </h2>
            <p>การออกแบบระบบแอปพลิเคชัน เพื่อรองรับการใช้งานบนโทรศัพท์ ซึ่งเป็นตัวช่วยในการเข้าถึงบริการหรือสินค้าของคุณ</p>
            <div class="bar"></div>
        </div>
        <div class="tab schedule-list-tab">
            <ul class="tabs">
                <li><a href="#"><span><i class="flaticon-analysis"></i> เว็บไซต์แอปพลิเคชัน</span></a></li>
                <li><a href="#"><span><i class="flaticon-optimize"></i> แอปพลิเคชัน</span></a></li>
                <!-- <li><a href="#"><span><i class="flaticon-software"></i> การยิงแอดในโซเซียล</span></a></li>
                <li><a href="#"><span><i class="flaticon-internet"></i> การวางแผนมีเดีย</span></a></li> -->
                <!-- <li><a href="#"><span><i class="flaticon-data"></i> Social Marketing</span></a></li> -->
            </ul>
            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-content">
                                <h2>เว็บไซต์แอปพลิเคชัน</h2>
                                <p>ในโลกที่ธุรกิจดำเนินด้วยเทคโนโลยี แอปพลิเคชันจึงถือเป็นอีกหนึ่งช่องทางในการทำให้ธุรกิจของคุณ หรือโปรเจคของคุณ สามารถดำเนินการได้อย่างมีประสิทธิภาพมากขึ้น
                                </p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>สะดวกต่อการใช้งาน</h3>
                                    <p>เราสามารถตั้งระบบ สร้างเว็บแอปพลิเคชันเพื่อให้สะดวกต่อการใช้งาน</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>เข้าถึงได้ง่าย</h3>
                                    <p>ผู้บริโภคสามารถเข้าถึงสินค้าและบริการได้ตลอดเวลาไม่ว่าจะอยู่ที่ไหน</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>รองรับหลายธุรกิจ</h3>
                                    <p>ไม่ว่าธุรกิจของคุณเป็นรูปแบบไหนเพียงแค่ติดต่อเรา ธุรกิจของคุณจะเข้าถึงลูกค้าได้มากขึ้นอย่างแน่นอน
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/alpaca/p4-2.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-content">
                                <h2>แอปพลิเคชัน</h2>
                                <p>สินค้าหรือบริการของคุณสามารถแสดงผลผ่านเว็บบราวเซอร์ได้โดยไม่ต้องติดตั้งแอป</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ทุกแพลตฟอร์ม</h3>
                                    <p>ทางเราได้สร้างระบบแอปพลิเคชันที่รองรับการใช้งานได้ทุกแพล็ตฟอร์ม</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ใช้ได้ทั่วโลก</h3>
                                    <p>ไม่ว่าอยู่ที่ไหนระบบแอปพลิเคชัน ก็สามารถใช้งานได้อยู่ไม่จำกัดพื้นที่</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ต่อยอดได้</h3>
                                    <p>เราสามารถขยายฐานบริการเพื่อต่อยอดให้ธรุกิจของคุณให้ถึงเป้าหมาย</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/alpaca/p4-2-1.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-content">
                                <h2>Online Marketing</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>Super Responsive</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>High Security</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>Optimal Choice</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/tab.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-content">
                                <h2>Email Marketing</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>Super Responsive</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>High Security</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>Optimal Choice</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/tab.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="tab-content">
                                <h2>Social Marketing</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>Super Responsive</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>High Security</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>Optimal Choice</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/tab.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="pricing-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Pricing</span> Plan</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-pricing">
                    <div class="pricing-header">
                        <h3>Basic</h3>
                        <p>Business Up</p>
                    </div>
                    <div class="price">
                        <sup>$</sup>29<sub>/mo</sub>
                    </div>
                    <ul class="pricing-list">
                        <li><i class="flaticon-checked"></i> 10 GB Hosting </li>
                        <li><i class="flaticon-checked"></i> 2 Unique Users </li>
                        <li><i class="flaticon-checked"></i> 12 GB Capacity</li>
                        <li><i class="flaticon-cancel"></i> Any Where Access</li>
                        <li><i class="flaticon-cancel"></i> Weekly Backup</li>
                        <li><i class="flaticon-cancel"></i> Support 24/Hour</li>
                    </ul>
                    <div class="price-btn">
                        <a routerLink="/" class="default-btn">Buy Now</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-pricing">
                    <div class="pricing-header">
                        <h3>Standard</h3>
                        <p>Business Up</p>
                    </div>
                    <div class="price">
                        <sup>$</sup>79<sub>/mo</sub>
                    </div>
                    <ul class="pricing-list">
                        <li><i class="flaticon-checked"></i> Visitor Info</li>
                        <li><i class="flaticon-checked"></i> Quick Responses</li>
                        <li><i class="flaticon-checked"></i> 12 GB Capacity</li>
                        <li><i class="flaticon-checked"></i> Any Where Access</li>
                        <li><i class="flaticon-cancel"></i> Weekly Backup</li>
                        <li><i class="flaticon-cancel"></i> Support 24/Hour</li>
                    </ul>
                    <div class="price-btn">
                        <a routerLink="/" class="default-btn">Buy Now</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-pricing">
                    <div class="pricing-header">
                        <h3>Premium</h3>
                        <p>Business Up</p>
                    </div>
                    <div class="price">
                        <sup>$</sup>99<sub>/mo</sub>
                    </div>
                    <ul class="pricing-list">
                        <li><i class="flaticon-checked"></i> 10 GB Hosting </li>
                        <li><i class="flaticon-checked"></i> 2 Unique Users </li>
                        <li><i class="flaticon-checked"></i> 12 GB Capacity</li>
                        <li><i class="flaticon-checked"></i> Any Where Access</li>
                        <li><i class="flaticon-checked"></i> Weekly Backup</li>
                        <li><i class="flaticon-cancel"></i> Support 24/Hour</li>
                    </ul>
                    <div class="price-btn">
                        <a routerLink="/" class="default-btn">Buy Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section> -->