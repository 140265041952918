import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { GoogleTagManagerService } from "angular-google-tag-manager";
import {CookieService} from "../app/services/cookie.service"
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;

    constructor(private router: Router,private gtmService:GoogleTagManagerService,public cookiesService : CookieService ) {
    }

    ngOnInit(){
        this.recallJsFuntions();
    }

    recallJsFuntions() {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationStart ) {
                $('.preloader-area').fadeIn('slow');
            }
        });
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/main.js');
            $('.preloader-area').fadeOut('slow');
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
            if (this.cookiesService.isAcceptCookies == true) {
                console.log('true')
                if (event instanceof NavigationEnd) {
                    const gtmTag = {
                        event: "page",
                        isGranted: 'true',
                        pageName: event.url,
                    };

                    this.gtmService.pushTag(gtmTag);
                    this.gtmService.addGtmToDom()
                }
            }
        });
    }
}
