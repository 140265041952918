import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {Customer} from '../../../customer.model'

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  displayedColumns: string[] = ['name', 'contact', 'email', 'description', 'company'];
  dataSource: MatTableDataSource<Customer>;
  password = "alpaca2021";
  input: string;
  isLogin: boolean = false;
  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.http.get('https://alpacathailand.com/api/getContact.php').subscribe((res: Customer[]) => {
      console.log(res);
      this.dataSource = new MatTableDataSource<Customer>(res);
    })
  }

  login() {
    if (this.input == this.password){
      this.isLogin = true;
    }
  }

}
