import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-services-four',
  templateUrl: './services-four.component.html',
  styleUrls: ['./services-four.component.scss']
})
export class ServicesFourComponent implements OnInit {

  constructor(private meta: Meta, private title: Title) { }

  ngOnInit(): void {
    this.title.setTitle("โปรดักชั่น | Alpaca Thailand")
  }

}
