import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";


@Component({
    selector: "app-navbar-one",
    templateUrl: "./navbar-one.component.html",
    styleUrls: ["./navbar-one.component.scss"],
})
export class NavbarOneComponent implements OnInit {
    acceptJob: boolean = true;
    acceptJustJob:boolean = true;
    acceptTraineeJob: boolean = true;
    constructor(private http: HttpClient, private translate: TranslateService) {

        translate.setDefaultLang('th');

        translate.use('th')
    }

    ngOnInit(): void {
        this.getJobOpen();
        this.getTraineeJobOpen();
        this.getJustJobOpen();
    }

    getJobOpen() {
        this.http
            .get("https://alpacathailand.com/api/manageJobApp.php")
            .subscribe((x: any) => {
                console.log(x);
                this.acceptJob = x == 1 ? true : false;
                console.log(this.acceptJob);
            });
    }

    getJustJobOpen() {
        this.http
            .get("https://alpacathailand.com/api/manageJustJobApp.php")
            .subscribe((x: any) => {
                console.log(x);
                this.acceptJustJob = x == 1 ? true : false;
                console.log(this.acceptJustJob);
            });
    }

    getTraineeJobOpen() {
        this.http
            .get("https://alpacathailand.com/api/manageTraineeJobApp.php")
            .subscribe((x: any) => {
                console.log(x);
                this.acceptTraineeJob = x == 1 ? true : false;
                console.log(this.acceptTraineeJob);
            });
    }

    changeLanguage(x: number):void {
        console.log(x)
        if (x == 0) this.translate.use('en');
        if (x ==1) this.translate.use('th');
    }
}
