<app-navbar-one></app-navbar-one>
<div class="main-banner-area-six">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <h1>Line Official Account</h1>
                            <p>บัญชี Line เพื่อธุรกิจของคุณ อีกหนึ่งตัวช่วยในการเพิ่มยอดขาย และโปรโมทสินค้าของคุณให้มีความน่าสนใจมากยิ่งขึ้น</p>
                            <div class="banner-btn">
                                <a routerLink="/contact" class="default-btn">เริ่มกับเรา</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 pr-0">
                        <div class="banner-image">
                            <img src="assets/img/alpaca/lineoa.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</div>

<section class="digital-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="digital-image">
                    <img src="assets/img/alpaca/s4-2.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="digital-content">
                    <h3> <span>Line Official Account</span></h3>
                    <div class="bar"></div>
                    <p>บัญชี LINE ที่สามารถเข้ามาช่วยโปรโมทธุรกิจของคุณ ให้มีความน่าสนใจมากยิ่งขึ้น ด้วยการสามารถแชร์ข่าวสาร บริการ สินค้า หรือโปรโมชั่นต่าง ๆ ให้กับลูกค้าที่ติดตาม Line OA ของคุณทั้งหมดได้โดยตรงภายในครั้งเดียว ทั้งนี้ ยังจะช่วยยกระดับภาพลักษณ์ของแบรนด์ให้ดูเป็นมืออาชีพมากยิ่งขึ้น
                    </p>
                    <ul class="digital-list">
                        <li><i class="flaticon-check"></i> Rich Menu</li>
                        <li><i class="flaticon-check"></i> Broadcast</li>
                        <li><i class="flaticon-check"></i> Reward Card</li>
                        <li><i class="flaticon-check"></i> Line Bot</li>
                        <li><i class="flaticon-check"></i> เชื่อมไลน์กับเว็บไซต์</li>
                        
                        <li><i class="flaticon-check"></i> การแจ้งเตือนผ่าน Line OA</li>
                    </ul>
                    <div class="digital-btn">
                        <a target="_blank" href = "https://page.line.me/552vuhwn" class="default-btn">Add Me</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
