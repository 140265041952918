<app-navbar-one></app-navbar-one>

<!-- <section class="agency-services-section ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services">
                    <div class="image">
                        <a routerLink="/services-details"><img src="assets/img/agency-services/img1.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>การจัดการโซเชียลมีเดีย</h3>
                        <span>Web Design</span>
                        <a routerLink="/services-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services">
                    <div class="image">
                        <a routerLink="/services-details"><img src="assets/img/agency-services/img2.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>การตลาดออนไลน์</h3>
                        <span>Agency</span>
                        <a routerLink="/services-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services">
                    <div class="image">
                        <a routerLink="/services-details"><img src="assets/img/agency-services/img3.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>มีเดียโปรดักชั่น</h3>
                        <span>Solutions</span>
                        <a routerLink="/services-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services">
                    <div class="image">
                        <a routerLink="/services-details"><img src="assets/img/agency-services/img4.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>การพัฒนาเว็บไซต์</h3>
                        <span>Analysis</span>
                        <a routerLink="/services-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services">
                    <div class="image">
                        <a routerLink="/services-details"><img src="assets/img/agency-services/img5.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>การพัฒนาเว็บไซต์</h3>
                        <span>Marketing</span>
                        <a routerLink="/services-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services">
                    <div class="image">
                        <a routerLink="/services-details"><img src="assets/img/agency-services/img6.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>ที่ปรึกษา</h3>
                        <span>Explanation</span>
                        <a routerLink="/services-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</section>

<section class="contact-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="contact-text">
                    <h3>ข้อมูล</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed eiusmod tempor incididunt ut labore </p>
                </div>
                <div class="contact-form">
                    <form id="contactForm">
                        <div class="form-group">
                            <label>ชื่อเต็ม</label>
                            <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                        </div>
                        <div class="form-group">
                            <label>อีเมล์</label>
                            <input type="email" name="email" id="email" class="form-control" placeholder="Your Email">
                        </div>
                        <div class="form-group">
                            <label>เบอร์</label>
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Your Subject">
                        </div>
                        <div class="form-group">
                            <label>คำอธิบาย</label>
                            <textarea name="message" class="form-control" id="message" cols="30" rows="6" placeholder="Your Message"></textarea>
                        </div>
                        <div class="send-btn">
                            <button type="submit" class="default-btn">Start</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-image">
                    <img src="assets/img/contact.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section> -->

<div class="main-banner-area-six">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <span>การบริการของเรา</span>
                            <h1>ที่ปรึกษา</h1>
                            <p>ในโลกของธรุกิจ ทุกคนจะได้รับทั้งเงินและประสบการณ์  แต่เราจะต้องรับประสบการณ์ก่อนและจำนวนเงินจะตามมาทีหลังเอง</p>
                            <div class="banner-btn">
                                <a routerLink="/contact" class="default-btn">เริ่มกับเรา</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 pr-0">
                        <div class="banner-image">
                            <img src="assets/img/alpaca/services/services1/con.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</div>


<section class="digital-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="digital-image">
                    <img src="assets/img/alpaca/p3-2.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="digital-content">
                    <h3> <span>ที่ปรึกษา</span></h3>
                    <div class="bar"></div>
                    <p>ทีมงานผู้เชี่ยวชาญด้านโซเชียลมีเดียขององค์กรเรา พร้อมที่จะจัดการและให้ความช่วยเหลือสตาร์ทอัพในประเทศไทย เกี่ยวกับการวางแผนการตลาดออนไลน์ในแพลตฟอร์มต่าง ๆ รวมไปถึงการสร้างสรรค์ผลงานออกแบบให้มีเอกลักษณ์ถูกใจลูกค้า</p>
                    <ul class="digital-list">
                        <li><i class="flaticon-check"></i> การจัดการที่สำคัญ</li>
                        <li><i class="flaticon-check"></i> ผู้ปรึกษาที่เชี่ยวชาญ</li>
                        <!-- <li><i class="flaticon-check"></i> Youtube Marketing</li>
                        <li><i class="flaticon-check"></i> Social Marketing</li>
                        <li><i class="flaticon-check"></i> Facebook Marketing</li>
                        <li><i class="flaticon-check"></i> Page Ranking</li> -->
                    </ul>
                    
                </div>
            </div>
        </div>
    </div>
</section>

<section class="tab-section pb-100">
    <div class="container">
        <div class="section-title">
            <h2>ผู้ปรึกษาที่ดีจะนำพาธุรกิจให้ <span>เติบโต</span> </h2>
            <p>เราจะทำหน้าที่ของเราอย่างเต็มที่เพื่อจะนำพาคุณไปสู่ความสำเร็จ</p>
            <div class="bar"></div>
        </div>
        <div class="tab schedule-list-tab">
            <ul class="tabs">
                <li><a href="#"><span><i class="flaticon-analysis"></i> การจัดการที่สำคัญ</span></a></li>
                <li><a href="#"><span><i class="flaticon-optimize"></i> ผู้เชี่ยวชาญด้านการปรึกษา</span></a></li>
                <li><a href="#"><span><i class="flaticon-software"></i> การยิงแอดในโซเซียล</span></a></li>
                <li><a href="#"><span><i class="flaticon-internet"></i> การวางแผนมีเดีย</span></a></li>
                <!-- <li><a href="#"><span><i class="flaticon-data"></i> Social Marketing</span></a></li> -->
            </ul>
            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-content">
                                <h2>การจัดการที่สำคัญ</h2>
                                <p>การจัดการกับวิกฤติที่เกิดขึ้นบนโลกออนไลน์ การรับมือวิกฤตบนโลกโซเชียล ไม่ให้ลุกลามหรือกระจายไปอย่างรวดเร็ว เนื่องจากจะส่งผลเสียต่อแบรนด์ และธุรกิจอย่างรุนแรง</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>รวดเร็วทันที</h3>
                                    <p>เมื่อเกิดวิกฤติการจัดการต้องถูกแก้อย่างรวดเร็ว เพื่อป้องกันการลุกลามของปัญหาที่เกิดขึ้น</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>รัดกุม</h3>
                                    <p>การวางแผนแก้ปัญหาอย่างรัดกุมในหลากหลายมิติ เพราะปัญหาที่เกิดขึ้น มีทั้งสถานการณ์เริ่มต้น ฉุกเฉิน วิกฤติ</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ทีมงานและเครื่องมือพร้อม</h3>
                                    <p>ใช้เครื่องมือเพื่อติดตามข้อมูลบนแพลตฟอร์มต่างๆ เพื่อดูความเคลื่อนไหว พร้อมทีมงานที่คอยติดตามตลอดเวลา
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/alpaca/p1-1.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-content">
                                <h2>ผู้เชี่ยวชาญด้านการปรึกษา</h2>
                                <p>เราสามารถให้คำปรึกษาเพื่อให้ธุรกิจของคุณเติบโตตามเป้าหมาย เพราะการเป็นที่ปรึกษาที่ดีเราต้องนำทางให้คุณประสบความสำเร็จ</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ที่ปรึกษาที่ชาญฉลาด</h3>
                                    <p>บริการให้คำปรึกษาทั้งวางแผน แนะนำ รวมไปถึงการใช้เครื่องมือต่าง ๆ เพื่อให้การสื่อสารบนโลกออนไลน์มีประสิทธิภาพมากที่สุด</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>หลากหลายทักษะ</h3>
                                    <p>เรามีการทำงานที่หลากหลายประเภทบนโลกออนไลน์ ดังนั้นเราจึงเป็นบริษัทที่ครอบคลุมมากที่สุดในเรื่องที่เกี่ยวกับ ดิจิทัลเทคโนโลยี</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>การวิเคราะห์ชั้นเลิศ</h3>
                                    <p>เราใช้ข้อมูลในการวิเคราะห์เพื่อให้เกิดความแม่นยำสูงสุด พร้อมด้วยประสบการณ์ที่สั่งสมมา</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/alpaca/p1-3.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-content">
                                <h2>การยิงแอดในโซเชียล</h2>
                                <p>การทำธุรกิจในโลกออนไลน์ให้ประสบความสำเร็จ ปัจจัยที่มีส่วนหนุนเสริมสำคัญก็คือการทำโฆษณา ซึ่งการยิง Ads ถือเป็นปัจจัยสำคัญในการกระตุ้นให้ผู้คนที่แพตฟอร์มต่าง ๆ สามารถเข้าถึงโพสต์สินค้าของเรามากขึ้น
                                </p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ได้รับการตอบรับอย่างดี</h3>
                                    <p>ท่านสามารถเพิ่มยอดการเข้าถึงและยอดขาย จากการยิง Ads ได้อย่างแม่นยำกับทางบริษัทเรา</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ความปลอดภัยสูง</h3>
                                    <p>ข้อมูลและความเป็นส่วนตัวของท่านจะได้รับการเก็บ และรักษาความปลอดภัยอย่างดีที่สุด</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ทางเลือกที่ดีที่สุด</h3>
                                    <p>เราไม่ใช่แค่ตัวเลือก แต่เราคือเพื่อนคู่คิดที่ท่านจะไม่สามารถขาดไปได้เลน ในการนำธุรกิจของท่านไปสู้เป้าหมายที่ตั้งเป้าหมายไว้</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/alpaca/p3-3.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-content">
                                <h2>การวางแผนมีเดีย</h2>
                                <p>คอนเทนต์บนโลกออนไลน์มีการแข่งขันที่สูงในหลาย ๆ ธุรกิจ การที่ปล่อยคอนเทนต์หรือสื่อสารสิ่งใดออกไปโดยที่ไม่มีการวางแผน ไม่เพียงแต่ต้องใช้กำลังในการทำ และยังไม่ได้ผลลัพท์ใด ๆ กลับมา</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>วางแผนอย่างมีประสิทธิภาพ</h3>
                                    <p>ช่วยในการตัดสินใจว่าช่องทางใด และแพลตฟอร์มไหนที่คุณจะสามารถแบ่งปันคอนเทนต์ได้อย่างมีประสิทธิภาพ</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ควบคุมงบประมาณ</h3>
                                    <p>ติดตามและควบคุมงบประมาณของคุณได้ในทุกช่วงตั้งแต่การสร้าง เผยแพร่ และแบ่งปันคอนเทนต์ที่มีคุณภาพและน่าสนใจ</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>วัดผลได้</h3>
                                    <p>ช่วยในการวิเคราะห์ เพื่อวัดผลความสำเร็จของกระบวนการวางแผนการสื่อสารเพื่อนำไปต่อยอดในอนาคต</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/alpaca/p1-4.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="tab-content">
                                <h2>Social Marketing</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>Super Responsive</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>High Security</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>Optimal Choice</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/alpaca/p3-3.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="pricing-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Pricing</span> Plan</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-pricing">
                    <div class="pricing-header">
                        <h3>Basic</h3>
                        <p>Business Up</p>
                    </div>
                    <div class="price">
                        <sup>$</sup>29<sub>/mo</sub>
                    </div>
                    <ul class="pricing-list">
                        <li><i class="flaticon-checked"></i> 10 GB Hosting </li>
                        <li><i class="flaticon-checked"></i> 2 Unique Users </li>
                        <li><i class="flaticon-checked"></i> 12 GB Capacity</li>
                        <li><i class="flaticon-cancel"></i> Any Where Access</li>
                        <li><i class="flaticon-cancel"></i> Weekly Backup</li>
                        <li><i class="flaticon-cancel"></i> Support 24/Hour</li>
                    </ul>
                    <div class="price-btn">
                        <a routerLink="/" class="default-btn">Buy Now</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-pricing">
                    <div class="pricing-header">
                        <h3>Standard</h3>
                        <p>Business Up</p>
                    </div>
                    <div class="price">
                        <sup>$</sup>79<sub>/mo</sub>
                    </div>
                    <ul class="pricing-list">
                        <li><i class="flaticon-checked"></i> Visitor Info</li>
                        <li><i class="flaticon-checked"></i> Quick Responses</li>
                        <li><i class="flaticon-checked"></i> 12 GB Capacity</li>
                        <li><i class="flaticon-checked"></i> Any Where Access</li>
                        <li><i class="flaticon-cancel"></i> Weekly Backup</li>
                        <li><i class="flaticon-cancel"></i> Support 24/Hour</li>
                    </ul>
                    <div class="price-btn">
                        <a routerLink="/" class="default-btn">Buy Now</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-pricing">
                    <div class="pricing-header">
                        <h3>Premium</h3>
                        <p>Business Up</p>
                    </div>
                    <div class="price">
                        <sup>$</sup>99<sub>/mo</sub>
                    </div>
                    <ul class="pricing-list">
                        <li><i class="flaticon-checked"></i> 10 GB Hosting </li>
                        <li><i class="flaticon-checked"></i> 2 Unique Users </li>
                        <li><i class="flaticon-checked"></i> 12 GB Capacity</li>
                        <li><i class="flaticon-checked"></i> Any Where Access</li>
                        <li><i class="flaticon-checked"></i> Weekly Backup</li>
                        <li><i class="flaticon-cancel"></i> Support 24/Hour</li>
                    </ul>
                    <div class="price-btn">
                        <a routerLink="/" class="default-btn">Buy Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section> -->