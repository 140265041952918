<app-navbar-one></app-navbar-one>

<section class="agency-services-section ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services">
                    <div class="image">
                        <a routerLink="/socialmedia"><img src="assets/img/alpaca/project/1.jpeg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>การจัดการโซเชียลมีเดีย</h3>
                        <span>Social Media Management </span>
                        <a routerLink="/socialmedia" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services">
                    <div class="image">
                        <a routerLink="/services"><img src="assets/img/alpaca/project/2.jpeg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>การตลาดออนไลน์</h3>
                        <span>Digital Marketing</span>
                        <a routerLink="/services" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services">
                    <div class="image">
                        <a routerLink="/production"><img src="assets/img/alpaca/project/3.jpeg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>มีเดียโปรดักชั่น</h3>
                        <span>Media Production</span>
                        <a routerLink="/production" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services">
                    <div class="image">
                        <a routerLink="/websitedesign"><img src="assets/img/alpaca/project/4.jpeg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>การพัฒนาเว็บไซต์</h3>
                        <span>Web Development</span>
                        <a routerLink="/websitedesign" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services">
                    <div class="image">
                        <a routerLink="/software"><img src="assets/img/alpaca/project/5.jpeg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>ซอฟต์แวร์และแอปพลิเคชัน</h3>
                        <span>Software and Application</span>
                        <a routerLink="/software" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services">
                    <div class="image">
                        <a routerLink="/consult"><img src="assets/img/alpaca/project/6.jpeg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>ที่ปรึกษา</h3>
                        <span>Consultant</span>
                        <a routerLink="/consult" class="link-btn"></a>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</section>

<section class="contact-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 mt-2">
                <div class="contact-text">
                    <h3>ข้อมูล</h3>
                    <!-- <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed eiusmod tempor incididunt ut labore </p> -->
                </div>
                <div class="contact-form">
                    <form id="contactForm">
                        <div class="form-group">
                            <label>ชื่อ-นามสกุล</label>
                            <input type="text" name="name" id="name" class="form-control" placeholder="ชื่อ นามสกุล" [(ngModel)]="customer.name">
                        </div>
                        <div class="form-group">
                            <label>อีเมล์</label>
                            <input type="email" name="email" id="email" class="form-control" placeholder="alpaca@gmail.com"[(ngModel)]="customer.email">
                        </div>
                        <div class="form-group">
                            <label>เบอร์</label>
                            <input type="text" name="contact" id="contact" class="form-control" placeholder="0812345678"[(ngModel)]="customer.contact">
                        </div>
                        <div class="form-group">
                            <label>หน่วยงาน</label>
                            <input type="text" name="company" id="company" class="form-control" placeholder="บริษัทxxx"[(ngModel)]="customer.company">
                        </div>
                        <div class="form-group">
                            <label>คำอธิบาย</label>
                            <textarea name="des" class="form-control" id="des" cols="30" rows="6" placeholder="ต้องการติดต่อกับทางบริษัท"[(ngModel)]="customer.description"></textarea>
                        </div>
                        <div class="send-btn">
                            <button type="submit" class="default-btn" (click)="submit()">Start</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-image" style="margin-left: 200px;">
                    <img src="assets/img/alpaca/home/ser-1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section> 