<app-navbar-one></app-navbar-one>



<div class="main-banner-area-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <span>Alapaca Media & Technology Solutions</span>
                            <h1>{{ 'HOME.WEAREYSS' | translate}}</h1>
                            <div class="banner-btn">
                                <a routerLink="/contact" class="default-btn">{{ 'HOME.START' | translate}}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-image">
                            <img src="assets/img/alpaca/home/1-home.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</div>

<section class="choose-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'HOME.SECTION2.AGENCYHEAD' | translate}}</h2>
            <p>{{ 'HOME.SECTION2.AGENCYDES' | translate}}</p>
            <div class="bar"></div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-6">
                <div class="choose-text">
                    <div class="icon">
                        <i class="flaticon-shared-folder"></i>
                    </div>
                    <h3>{{ 'HOME.SECTION2.4COMPONENT.Pro.HEADING' | translate}}</h3>
                    <p>{{ 'HOME.SECTION2.4COMPONENT.Pro.DESC' | translate}}</p>
                </div>
                <div class="choose-text">
                    <div class="icon">
                        <i class="flaticon-laptop"></i>
                    </div>
                    <h3>{{ 'HOME.SECTION2.4COMPONENT.DT.HEADING' | translate}}</h3>
                    <p>{{ 'HOME.SECTION2.4COMPONENT.DT.DESC' | translate}}
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="image">
                    <img src="assets/img/alpaca/home/2-1.jpeg" alt="image">
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="choose-text">
                    <div class="icon">
                        <i class="flaticon-quality"></i>
                    </div>
                    <h3>{{ 'HOME.SECTION2.4COMPONENT.OE.HEADING' | translate}}</h3>
                    <p>{{'HOME.SECTION2.4COMPONENT.OE.DESC' | translate}}</p>
                </div>
                <div class="choose-text">
                    <div class="icon">
                        <i class="flaticon-target"></i>
                    </div>
                    <h3>{{'HOME.SECTION2.4COMPONENT.TRENDY.HEADING'| translate}}</h3>
                    <p>{{'HOME.SECTION2.4COMPONENT.TRENDY.DESC'| translate}}</p>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="partner-section ptb-50">
        <div class="container">
            <div class="partner-slider owl-carousel owl-theme">
                <div class="partner-item">
                    <a routerLink="/"><img src="/assets/img/partner/1.png" alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a routerLink="/"><img src="assets/img/partnar/2.png" alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a routerLink="/"><img src="assets/img/partnar/3.png" alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a routerLink="/"><img src="assets/img/partnar/4.png" alt="partner"></a>
                </div>
                <div class="partner-item">
                    <a routerLink="/"><img src="assets/img/partnar/5.png" alt="partner"></a>
                </div>
            </div>
        </div>
    </div> -->
</section>

<div class="partner-section ptb-50">
    <div class="container">
        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-item">
                <a routerLink="/"><img src="assets/img/alpaca/home/3-1.jpeg" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/"><img src="assets/img/alpaca/home/3-2.jpeg" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/"><img src="assets/img/alpaca/home/3-3.jpeg" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/"><img src="assets/img/alpaca/home/3-4.jpeg" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/"><img src="assets/img/alpaca/home/3-5.jpeg" alt="partner"></a>
            </div>
        </div>
    </div>
</div>

<section class="protfolio-section bg-color pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'HOME.SECTION3.HEADDER' | translate}} <span>{{ 'HOME.SECTION3.HEADDER2' | translate}} </span></h2>
            <p>{{ 'HOME.SECTION3.SUBHEADDER' | translate}}</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/socialmedia"><img src="assets/img/alpaca/project/1.jpeg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Social Media Management</h3>
                        <span>การบริหารจัดการโซเชียลมีเดีย</span>
                        <a routerLink="/socialmedia" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/projects"><img src="assets/img/alpaca/project/2.jpeg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Digital Marketing</h3>
                        <span>การตลาดออนไลน์</span>
                        <a routerLink="/projects" class="link-btn"></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/production"><img src="assets/img/alpaca/project/3.jpeg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Media Production</h3>
                        <span>มีเดียโปรดักชั่น</span>
                        <a routerLink="/production" class="link-btn"></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/websitedesign"><img src="assets/img/alpaca/project/4.jpeg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Web Development</h3>
                        <span>การพัฒนาเว็บไซต์</span>
                        <a routerLink="/websitedesign" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/software"><img src="assets/img/alpaca/project/5.jpeg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Software and App</h3>
                        <span>ซอฟต์แวร์และแอปพลิเคชัน</span>
                        <a routerLink="/software" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/consult"><img src="assets/img/alpaca/project/6.jpeg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Consultant</h3>
                        <span>ที่ปรึกษา</span>
                        <a routerLink="/consult" class="link-btn"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="counter-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>We have Completed <span>950+</span> Projects <span>Successfully</span></h2>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-counter">
                    <div class="icon">
                        <i class="flaticon-check"></i>
                    </div>
                    <h3><span class="odometer" data-count="29,648">00</span></h3>
                    <p>ผู้ติดตามบนเฟสบุ๊ค</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-counter">
                    <div class="icon">
                        <i class="flaticon-happy"></i>
                    </div>
                    <h3><span class="odometer" data-count="29,648">00</span></h3>
                    <p>ยอดไลค์ของอินฟลูเอนเซอร์ </p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-counter">
                    <div class="icon">
                        <i class="flaticon-technical-support"></i>
                    </div>
                    <h3><span class="odometer" data-count="10">00</span> <span>M</span></h3>
                    <p>การเข้าถึงทั้งหมดต่อเดือน</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-counter">
                    <div class="icon">
                        <i class="flaticon-trophy"></i>
                    </div>
                    <h3><span class="odometer" data-count="100">00</span></h3>
                    <p>อัตราประสบความสำเร็จร้อยละ</p>
                </div>
            </div>
        </div>
    </div>
</section> -->
<section class="review-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <h3><ng9-odometer [number]="29648" ></ng9-odometer></h3>
                            <p>{{ 'HOME.SECTION4.1' | translate }}</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <div class="icon">
                                <i class="flaticon-happy"></i>
                            </div>
                            <!-- <h3><span class="odometer odo-k" data-count="500">00</span></h3> -->
                            <h3><ng9-odometer class="odo-k" [number]="500" ></ng9-odometer></h3>
                            <p> {{ 'HOME.SECTION4.2' | translate }} </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <div class="icon">
                                <i class="flaticon-technical-support"></i>
                            </div>
                            <h3><ng9-odometer class="odo-m" [number]="10" ></ng9-odometer></h3>
                            <p>{{ 'HOME.SECTION4.3' | translate }}</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <div class="icon">
                                <i class="flaticon-trophy"></i>
                            </div>
                            <h3><ng9-odometer class="odo-p" [number]="24" ></ng9-odometer></h3>
                            <p>{{ 'HOME.SECTION4.4' | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="review-image">
                    <img src="assets/img/review.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<!-- section5 -->
<section class="subscribe-inner-area ptb-100">
    <div class="container">
        <div class="subscribe-inner-text">
            <h2>{{'HOME.SECTION5.HEADING'| translate}}</h2>
            <h4>{{'HOME.SECTION5.SUB'| translate}}</h4>
            <!-- <p>#AlpacaMediaandTechnologySolutions</p> -->
            <!-- <form class="newsletter-form">
                <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                <button type="submit">Subscribe</button>
            </form> -->
        </div>
    </div>
</section>


<!-- <section class="services-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>IT  Agency Services</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-two">
                    <div class="icon">
                        <i class="flaticon-it"></i>
                    </div>
                    <h3>IT Professionals</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-two bg-fc9ba7">
                    <div class="icon">
                        <i class="flaticon-setting"></i>
                    </div>
                    <h3>Web Development</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-two bg-2cdcf7">
                    <div class="icon">
                        <i class="flaticon-promotion"></i>
                    </div>
                    <h3>Digital Marketing</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-two bg-009af0">
                    <div class="icon">
                        <i class="flaticon-optimize"></i>
                    </div>
                    <h3>Software Engineers</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-two bg-f4d62c">
                    <div class="icon">
                        <i class="flaticon-cloud-computing"></i>
                    </div>
                    <h3>Data Analysis</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-two bg-1e2d75">
                    <div class="icon">
                        <i class="flaticon-laptop"></i>
                    </div>
                    <h3>SEO & Content</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section> -->

<!-- <section class="blog-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2> <span>ผลงาน</span>ของเรา</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-item">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/img1.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>20 March 2020</span>
                        <h3><a routerLink="/blog-details">Great Tips To Earn More Money From Digital Industry</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-item">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/img2.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>25 April 2020</span>
                        <h3><a routerLink="/blog-details">The Billionaire Guide On Design That will Get You Rich</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-item">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/img3.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>30 July 2020</span>
                        <h3><a routerLink="/blog-details">Making Peace With The Feast Or Famine Of Freelancing</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- -->
<section class="projects-section pt-100 pb-70">
    <div class="container-fluid">
        <div class="section-title">
            <h2>{{'HOME.SECTION5.SUBHEADER' | translate}} <span></span></h2>

            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/alpaca/the.jpeg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>The Publisher</h3>
                    </div>
                    <a target="_blank" href = "https://www.facebook.com/ThePublisherth" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/alpaca/guru.jpeg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>กูรูวัยเก๋า</h3>
                    </div>
                    <a target="_blank" href="https://www.facebook.com/guruwaigao/" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/alpaca/digi.jpeg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>Digital Alert</h3>
                    </div>
                    <a class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/alpaca/rbs.jpeg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>RBS Group</h3>
                    </div>
                    <a target="_blank" href = "http://www.rbs-design.com/redesign/home" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/alpaca/move.jpeg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>Move Applications</h3>
                    </div>
                    <a target="_blank" href = "https://www.facebook.com/Move-by-rbs-101059511989496/" class="link-btn"></a>
                </div>
                <div class="single-projects-box" >
                    <div class="projects-image" >
                        <img style = "height:325px"src="assets/img/alpaca/st.jpeg" alt="image" >
                    </div>
                    <div class="projects-content">
                        <h3>Save Thai Fight Covid</h3>
                    </div>
                    <a target="_blank" href = "https://www.facebook.com/ThePublisherth" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-projects-box">
                    <div class="projects-image">
                        <img src="assets/img/alpaca/seed.jpeg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>SEED</h3>
                    </div>
                    <a target="_blank" href = "https://www.seed-thailand.com/" class="link-btn"></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- <section class="team-section pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Expert Team</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/img1.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Alex Maxwel</h3>
                        <span>Founder</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/img2.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Williams Halimton</h3>
                        <span>Manager</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/img3.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Juhon Dew</h3>
                        <span>CEO</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section> -->

<!-- SECTION6 -->
<section class="audience-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="audience-image">
                    <img src="assets/img/alpaca/line.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="audience-content">
                    <h3><span>{{'HOME.SECTION6.HEAD1'| translate}}</span>{{'HOME.SECTION6.HEAD2'| translate}}</h3>
                    <div class="bar"></div>
                    <p>{{'HOME.SECTION6.SUB1'| translate}}</p>
                    <p>{{'HOME.SECTION6.SUB2'| translate}}</p>
                    <div class="audience-btn">
                        <a  class="default-btn" target="_blank" href = "https://page.line.me/552vuhwn">{{'HOME.SECTION6.BTN'| translate}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>
