<div class="navbar-area">
    <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
            <a class="navbar-brand" routerLink="/">
                <img src="/assets/img/alpaca/alpacalogo1.png" width="120px" height="40px" alt="logo">
            </a>

            <button class="navbar-toggler" type="button">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse">
                <ul class="navbar-nav">
                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/"
                            class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{
                            'NAVBAR.HOME' | translate}}</a>

                    </li>


                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                            routerLink="/about" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">{{ 'NAVBAR.ABOUT' | translate}}</a></li>

                    <li class="nav-item"><a routerLink="/services" class="nav-link">{{ 'NAVBAR.SERVICES' | translate}}<i
                                class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/services" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">{{ 'NAVBAR.SERVICES' | translate}}</a>
                            </li>
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/consult" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">{{ 'NAVBAR.CONSULTANT' | translate}}</a>
                            </li>
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/software" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">{{ 'NAVBAR.SOFTWARE' | translate}}</a>
                            </li>
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/socialmedia" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">{{ 'NAVBAR.SOCIAL' | translate}}</a></li>
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/production" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">{{ 'NAVBAR.PRODUCTION' | translate}}</a>
                            </li>
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/websitedesign" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">{{ 'NAVBAR.WEBSITE' | translate}}</a></li>
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/lineoa" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Line Official Account</a></li>
                        </ul>
                    </li>
                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                            routerLink="/charity" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">{{ 'NAVBAR.CHARITY' | translate}}</a></li>

                    <li class="nav-item"><a routerLink="/projects" class="nav-link">{{ 'NAVBAR.PROJECT' |
                            translate}}</a>
                        <!-- <ul class="dropdown-menu">
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/projects-one" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Projects</a></li>

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/projects-details" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Projects details</a></li>
                        </ul> -->
                    </li>

                    <!-- <li class="nav-item"><a routerLink="/blog-grid" class="nav-link">บล๊อก <i
                                class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/blog-details" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a
                                    routerLink="/blog-right-sidebar" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Blog Right Sidebar</a></li>
                        </ul>
                    </li> -->

                    <li class="nav-item " data-toggle="collapse" data-target=".navbar-collapse.show"><a
                            routerLink="/contact" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">{{ 'NAVBAR.CONTACT' | translate}}</a></li>

                    <li *ngIf="acceptJob" class="nav-item " data-toggle="collapse" data-target=".navbar-collapse.show">
                        <a routerLink="/job-application" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">{{ 'NAVBAR.WORK' | translate}}<i
                                class='bx bx-chevron-down'></i></a>

                        <ul class="dropdown-menu">

                            <li *ngIf="acceptJustJob" class="nav-item" data-toggle="collapse"
                                data-target=".navbar-collapse.show"><a routerLink="/job-application" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">สมัครงาน</a>
                            </li>
                            <li *ngIf="acceptTraineeJob" class="nav-item" data-toggle="collapse"
                                data-target=".navbar-collapse.show"><a routerLink="/trainee-job-app" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">สมัครฝึกงาน</a>
                            </li>

                        </ul>
                    </li>

                    <li *ngIf="acceptJob" class="nav-item " data-toggle="collapse" data-target=".navbar-collapse.show">
                        <a routerLink="/job-application" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">EN /TH<i class='bx bx-chevron-down'></i></a>

                        <ul class="dropdown-menu">
                            <li class="nav-item" >
                                <a class="nav-link" (click)="changeLanguage(0)" style="cursor: pointer;"> EN</a>
                            </li>
                            <li class="nav-item" >
                                <a class="nav-link" (click)="changeLanguage(1)" style="cursor: pointer;"> TH</a>
                            </li>

                        </ul>
                    </li>

                </ul>
                <div class="others-options">
                    <!-- <div>
                        <ul>
                            <li>TH</li>
                            <li>EN</li>
                        </ul>
                    </div> -->
                    <!-- <div class="option-item"><i class="search-btn flaticon-search"></i>
                        <i class="close-btn flaticon-cancel"></i>
                        <div class="search-overlay search-popup">
                            <div class='search-box'>
                                <form class="search-form">
                                    <input class="search-input" name="search" placeholder="Search" type="text">
                                    <button class="search-button" type="submit"><i class="flaticon-search"></i></button>
                                </form>
                            </div>
                        </div>
                    </div> -->

                    <div class="burger-menu">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</div>
