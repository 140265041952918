<section class="footer-section pt-100 pb-70">
    <div class="container">
        <div class="subscribe-area">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="subscribe-content">
                        <h2>Alpaca media and technology solutions</h2>
                    </div>
                </div>
                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <!-- <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL"> -->
                        <button type="submit" routerLink="/contact">ติดต่อเรา</button>
                    </form>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>{{ 'FOOTER.SECTION1.ABOUT' | translate}}</h3>
                    </div>
                    <p>{{ 'FOOTER.SECTION1.DES' | translate}}</p>
                    <ul class="footer-social">
                        <li><a href="" target="_blank" href = "https://www.facebook.com/Alpacathailandmedia"><i class="flaticon-facebook"></i></a></li>
                        <!-- <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-pinterest"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li> -->
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>สารบัญ</h3>
                    </div>
                    <ul class="footer-quick-links">
                        <li><a routerLink="/about"><p>เกี่ยวกับเรา</p></a></li>
                        <li><a routerLink="/services"><p>การบริการ</p></a></li>
                        <li><a routerLink="/projects"><p>ผลงาน</p></a></li>
                        <!-- <li><a routerLink="/blog-details"><p>บล็อก</p></a></li> -->
                        <li><a routerLink="/contact"><p>ติดต่อ</p></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>ผลงาน</h3>
                    </div>
                    <ul class="footer-quick-links">
                        <li><a routerLink="/socialmedia"><p>การจัดการโซเชียลมีเดีย</p></a></li>
                        <li><a routerLink="/"><p>การจัดการการตลาดบนโลก</p></a></li>
                        <li><a routerLink="/services"><p>Services</p></a></li>
                        <li><a routerLink="/websitedesign"><p>UI/UX Design</p></a></li>
                        <li><a routerLink="/"><p>Support Engineer</p></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>ติดต่อ</h3>
                    </div>
                    <div class="footer-info-contact">
                        <i class="flaticon-phone-call"></i>
                        <h3>เบอร์</h3>
                        <span><a href="tel:+882-569-756">061-4165451</a></span>
                    </div>
                    <div class="footer-info-contact">
                        <i class="flaticon-envelope"></i>
                        <h3>อีเมล</h3>
                        <span><a href="mailto:contactus@alpacathailand.com">contactus@alpacathailand.com</a></span>
                    </div>
                    <div class="footer-info-contact">
                        <i class="flaticon-pin"></i>
                        <h3>ที่อยู่</h3>
                        <span><a href="#" target="_blank">อาคารไอทาวเวอร์ ชั้น 15  888  ถ.วิภาวดีรังสิต แขวงจตุจักร เขตจตุจักร กรุงเทพมหานคร 10900 ประเทศไทย
                        </a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <div class="copyright-area">
    <div class="container">
        <div class="copyright-area-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p><i class='bx bx-copyright'></i>2020 Avrax. All Rights Reserved by <a href="https://envytheme.com/" target="_blank">EnvyTheme</a></p>
                </div>
                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/terms-conditions">Terms & Conditions</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="go-top"><i class="bx bx-chevron-up"></i><i class="bx bx-chevron-up"></i></div>

<div class="sidebar-modal">
    <div class="sidebar-modal-inner">
        <div class="sidebar-about-area">
            <div class="title">
                <h2>เกี่ยวกับเรา</h2>
                <p>เราคาดหวังที่จะเป็นผู้นำในด้านดิจิทัลเทคโนโลยี เพื่อก้าวทันการเปลี่ยนแปลงในยุคของการเปลี่ยนแปลงที่เกิดจากเทคโนโลยีดิจิทัล( Digital Disruption) เราจึงมาพร้อมความมุ่งมั่นที่จะยกระดับการสื่อสารควบคู่ไปกับเทคโนยีที่รวมกันเพื่องสร้างสรรค์สิ่งที่ดีที่สุด</p>
            </div>
        </div>
        <div class="sidebar-contact-feed">
            <h2>ติดต่อ</h2>
            <div class="contact-form">
                <form id="contactForm">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="name" id="name" class="form-control" placeholder="ชื่อ" [(ngModel)]="customer.name">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="email" name="email" id="email" class="form-control" placeholder="อีเมลล์" [(ngModel)]="customer.email">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="เบอร์โทรศัพท์" [(ngModel)]="customer.contact">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="company" id="company" class="form-control" placeholder="บริษัทxxx" [(ngModel)]="customer.description">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea name="message" class="form-control" id="message" cols="30" rows="6" placeholder="คำอธิบาย" [(ngModel)]="customer.description"></textarea>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="send-btn">
                                <button type="submit" class="send-btn-one" (click)="submit()">เริ่มต้นกับเรา</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="sidebar-contact-area">
            <div class="contact-info">
                <div class="contact-info-content">
                    <h2>
                        <a href="tel:+0881306298615">061-416-5451</a>
                        <span>OR</span>
                        <a href="mailto:contactus@alpacathailand.com">contactus@alpacathailand.com</a>
                    </h2>
                    <ul class="social">
                        <li><a href="https://www.facebook.com/Alpacathailandmedia" target="_blank"><i class="flaticon-facebook"></i></a></li>
                        <!-- <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-pinterest"></i></a></li> -->
                    </ul>
                </div>
            </div>
        </div>
        <span class="close-btn sidebar-modal-close-btn">
            <i class="flaticon-cancel"></i>
        </span>
    </div>
</div>
