import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-projects-one',
  templateUrl:'./projects-one.component.html',
  styleUrls: ['./projects-one.component.scss']
})
export class ProjectsOneComponent implements OnInit {

  constructor(private meta: Meta,private title: Title) { }

  ngOnInit(): void {
    this.title.setTitle("ผลงาน | Alpaca Thailand")

  }
}
