import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

interface projectDes {
    [index: string]: any;
    slug: string;
    name: string;
    picA: string;
    picB: string;
    picC: string;
    con1: string;
    con2: string;
    con3: string;
    con4: string;
    con5: string;
}

@Component({
    selector: 'app-project-description',
    templateUrl: './project-description.component.html',
    styleUrls: ['./project-description.component.scss']
})
export class ProjectDescriptionComponent implements OnInit {

    name: string = ''

    currentProject: projectDes;

    constructor(private route: ActivatedRoute, private title: Title) { }



    public projects: projectDes[] = [
        {
            slug: 'publisher',name: "The Publisher", picA: "../../../../assets/img/alpaca/publisherA.jpg", picB: "../../../../assets/img/alpaca/publisherB.jpg", picC: "../../../../assets/img/alpaca/publisherC.jpg", con1: "บริษัท อัลปากา มีเดีย แอนด์ เทคโนโลยี โซลูชั่นส์ จํากัด จัดทำ “The Publisher” สำนักข่าวออนไลน์เพื่อสังคม เพื่อให้คนในสังคมได้รู้ทันข้อมูลข่าวสารที่ถูกต้องและทันเวลา รวมทั้งในสถานการณ์ปัจจุบันของสังคมที่เกิดกระแสข่าวขึ้นมากมาย ทางเพจ The Publisher มุ่งหวังว่าจะเป็นช่องทางเผยแพร่เนื้อหาข่าวเชิงบวกให้กับสังคมได้รับรู้มากยิ่งขึ้น"
            , con2: "โดยมีการจัดทำตั้งแต่การคัดสรรเนื้อข่าว, การเขียนเนื้อหา/แคปชัน/พาดหัวข่าว, การจัดทำรูปภาพและวิดีโอเชิงสร้างสรรค์สังคมเพื่อใช้สำหรับการเผยแพร่ลงในเพจ The Publisher เพื่อให้ผู้ติดตามได้รับข่าวสารที่ถูกต้อง เป็นประโยชน์ต่อตนเองและการสร้างสังคมที่ดีต่อไป", con3: "https://www.facebook.com/ThePublisherth ", con4: "", con5:""
        },
        {
            slug: 'guru',name: "กูรูวัยเก๋า", picA: "../../../../assets/img/alpaca/guruA.jpg", picB: "../../../../assets/img/alpaca/guruB.jpg", picC: "../../../../assets/img/alpaca/guruC.jpg", con1: "บริษัท อัลปากา มีเดีย แอนด์ เทคโนโลยี โซลูชั่นส์ จํากัด ร่วมกับ กรมกิจการผู้สูงอายุ หน่วยงานในสังกัดกระทรวงพัฒนาสังคมและความมั่นคงของมนุษย์ ผลิตรายการกูรูวัยเก๋า เป็นรายการที่จะพาไปรู้จักกับผู้สูงอายุในมุมมองและมิติใหม่ ว่าผู้สูงอายุก็ยังสามารถทำกิจกรรมต่าง ๆ ได้โดยที่ตัวเลขอายุไม่ใช่อุปสรรค สร้างกำลังใจแก่ผู้สูงอายุด้วยกันเองให้ลุกขึ้นมาทำกิจกรรมต่าง ๆ ด้วยกัน"
            , con2: "โดยมีการร่วมกันผลิตรายการออกมาทั้งหมด 10 ตอนและมีการเผยแพร่ผ่านช่องทาง YouTube และ Facebook Page ของรายการกูรูวัยเก๋า", con3: "https://www.facebook.com/guruwaigao/" , con4: "https://www.youtube.com/channel/UCsKxr-V2DH_9dRn1j2OH3Dw",con5:""
        },
        {
            slug: 'digi',name: "Digital Alert", picA: "../../../../assets/img/alpaca/digiA.png", picB: "../../../../assets/img/alpaca/digiB.jpg", picC: "../../../../assets/img/alpaca/digiC.jpg", con1: "บริษัท อัลปากา มีเดีย แอนด์ เทคโนโลยี โซลูชั่นส์ จํากัด ร่วมกับ SEED Thailand , วุฒิสภาและมูลนิธินักศึกษาสถาบันพระปกเกล้าเพื่อสังคม จัดกิจกรรม Digital Alert พี่สอนน้องให้รู้ทันโลกออนไลน์ “เมื่อเทคโนโลยีเปลี่ยนแปลง เด็กไทยต้องเปลี่ยนตาม” โดยรุ่นพี่โฆษก SEED Thailand \n ในหัวข้อ Digital Footprint กับการรับมือข่าวปลอม, รู้ทันการคุกคามบนโลกออนไลน์ และ Thailand Only"
            , con2: "โดยมีการดำเนินการตั้งแต่หาวิทยากร, การเตรียมจัดอบรมโครงการผ่านระบบออนไลน์ Zoom Meeting และการทำคลิปช่วง Highlight ของกิจกรรมในโครงการ", con3: "https://www.facebook.com/seedprojectthailand/videos/236060605078910" , con4: "", con5:""
        },
        {
            slug: 'seed',name: "Seed แตกหน่อพันธุ์D ไม่มีที่สิ้นสุด", picA: "../../../../assets/img/alpaca/seedA.jpg", picB: "../../../../assets/img/alpaca/seedB.jpg", picC: "../../../../assets/img/alpaca/seedC.jpg", con1: "บริษัท อัลปากา มีเดีย แอนด์ เทคโนโลยี โซลูชั่นส์ จํากัด ร่วมกับ SEED Thailand  จัดทำโครงการ Seed แตกหน่อพันธุ์D ไม่มีที่สิ้นสุด มุ่งหวังที่จะสร้างเครือข่ายเยาวชนรุ่นใหม่ภายใต้สังคมยุคดิจิทัล เพื่อนำเสนอความคิดวิธีการพัฒนาชุมชุนบ้านเกิด และประเทศชาติให้เจริญยิ่งขึ้น \n รวมทั้งมีการหยิบยกอัตลักษณ์ท้องถิ่นมาต่อยอดให้เกิดเป็นวิสาหกิจชุมชนรูปแบบใหม่ เพื่อสร้างงาน สร้างรายได้ และความมั่นคงในชีวิตให้กับท้องถิ่นและประเทศชาติ "
            , con2: "โดยได้มีการจัดทำเว็บไซต์เพื่อเป็นพื้นที่ประชาสัมพันธ์ในการลงข้อมูลของหน่วยงานและรูปภาพกิจกรรมที่จัดขึ้น รวมทั้งในอนาคตจะมีการพัฒนาระบบให้เป็น E-Classroom ชั้นเรียนออนไลน์เพื่อพัฒนา Soft skills ของเด็กและเยาวชนต่อไป",
            con3: "", con4: "", con5:"https://www.seed-thailand.com"
        },
        {
            slug: 'rbs',name: "RBS group", picA: "../../../../assets/img/alpaca/rbsA.jpg", picB: "../../../../assets/img/alpaca/rbsB.jpg", picC: "../../../../assets/img/alpaca/rbsC.jpg", con1: "บริษัท อัลปากา มีเดีย แอนด์ เทคโนโลยี โซลูชั่นส์ จํากัด ร่วมกับบริษัท RBS GROUP จำกัด บริษัทอุตสาหกรรมเหล็กแปรรูปซึ่งเป็นอุปกรณ์ในธุรกิจค้าปลีก และอุปกรณ์ในอุตสาหกรรมชิ้นส่วนต่าง ๆ ทั้งด้านการออกแบบ ผลิต ขนส่ง และติดตั้ง"
            , con2: "โดยได้มีการจัดทำเว็บแอปพลิเคชันระบบบริหารจัดการโลจิสติกส์ครบวงจรอัจฉริยะ เพื่อใช้สำหรับการขนส่งอย่างมีประสิทธิภาพ", con3: "", con4: "", con5:"http://www.rbs-design.com/redesign/home "
        },
        {
            slug: 'move',name: "Move Application", picA: "../../../../assets/img/alpaca/moveA.jpg", picB: "../../../../assets/img/alpaca/moveB.jpg", picC: "../../../../assets/img/alpaca/moveC.jpg", con1: "บริษัท อัลปากา มีเดีย แอนด์ เทคโนโลยี โซลูชั่นส์ จํากัด ร่วมกับบริษัท RBS GROUP จำกัด เป็นบริษัทอุตสาหกรรมเหล็กแปรรูปซึ่งเป็นอุปกรณ์ในธุรกิจค้าปลีก และอุปกรณ์ในอุตสาหกรรมชิ้นส่วนต่าง ๆ ทั้งด้านการออกแบบ ผลิต ขนส่ง และติดตั้ง"
            , con2: "โดยได้มีการจัดทำระบบบริหารจัดการขนส่งอัจฉริยะผ่าน Mobile Application สำหรับบุคคลกรการขนส่งในเครือ เพื่อสร้างความสะดวกต่อการเก็บข้อมูลในทุกกระบวนการขนส่งตั้งแต่ต้นทางจนถึงปลายทาง", con3: "", con4: "", con5:""
        },
        {
            slug: 'savethai', isVideo: true,name: "Save Thai Fight Covid ", picA: "../../../../assets/img/alpaca/savethaiA.png", picB: "../../../../assets/img/alpaca/savethaiClip.mp4", picC: "", con1: "โครงการ Save Thai Fight Covid เป็นโครงการภายใต้ความร่วมมือของเครือข่ายเยาวชน SEED Thailand, คณะกรรมาธิการวิสามัญการพิทักษ์และเทิดทูนสถาบันพระมหากษัตริย์, วุฒิสภา, กองทัพไทย, มูลนิธินักศึกษาสถาบันพระปกเกล้าเพื่อสังคม, บริษัทรีเทล บิซิเนส โซลูชั่นส์ จํากัด และกลุ่มบริษัทในเครือ (RBS group)"
            , con2: "บริษัท อัลปากา มีเดีย แอนด์ เทคโนโลยี โซลูชั่นส์ จํากัด ได้มีส่วนร่วมในการจัดทำ LINE Office Account (LINE OA) ให้ประชาชนลงทะเบียนรับกล่องยังชีพให้กับผู้ป่วยติดเชื้อไวรัสโควิด-19 ที่พักรักษาตัวอยู่บ้าน และการทำระบบจัดการเตียงของผู้ป่วยติดเชื้อไวรัสโควิด-19  รวมทั้งมีการจัดทำระบบช่วยเหลือผู้ป่วยให้ได้เข้ารับการรักษาตนเองในระบบของชุมชน (Community Isolation)", con3: '', con4: "", con5:""
        },
        {
            slug: 'ylng', name: "Youth Leader New Gen", picA: "../../../../assets/img/alpaca/seedA.jpg", picB: "../../../../assets/img/alpaca/youthB.jpg", picC: "../../../../assets/img/alpaca/youthC.jpg", con1: "บริษัท อัลปากา มีเดีย แอนด์ เทคโนโลยี โซลูชั่นส์ จํากัด ร่วมกับเครือข่ายเยาวชน Seed Thailand, กระทรวงวัฒนธรรม และสภาดิจิทัลเพื่อเศรษฐกิจและสังคมแห่งประเทศไทย จัดอบรมเชิงปฏิบัติการการมีส่วนร่วมในการป้องกันและแก้ไขปัญหาของเด็กและเยาวชนภายใต้กิจกรรม “Youth Leader New Gen” โดยสร้างผู้นำเยาวชนรุ่นใหม่จากทั้ง 4 ภาค ผ่านระบบออนไลน์​ Zoom Meeting"
            , con2: "โดยได้มีการจัดทำคลิปช่วง Highlight ของโครงการทั้งในช่วงของการบรรยายและช่วงของการนำเสนอจากเยาวชนทั้ง 4 ภูมิภาค ในหัวข้อ “ของดี บ้านฉัน”", con3: "https://www.facebook.com/watch/?v=975191540037336 ", con4: "", con5:""
        },
        {
            slug: 'huahin',name: "หัวหินบ้านของพ่อ", picA: "../../../../assets/img/alpaca/huahinA.jpg", picB: "../../../../assets/img/alpaca/huahinB.jpg", picC: "../../../../assets/img/alpaca/huahinC.jpg", con1: "บริษัท อัลปากา มีเดีย แอนด์ เทคโนโลยี โซลูชั่นส์ จํากัด ร่วมกับ จังหวัดประจวบคีรีขันธ์ คณะกรรมาธิการวิสามัญการพิทักษ์ และเทิดทูนสถาบันพระมหากษัตริย์ วุฒิสภา มูลนิธินักศึกษาสถาบันพระปกเกล้าเพื่อสังคมและเครือข่ายเยาวชน SEED Thailand จัดงาน \"หัวหินบ้านของพ่อ\" ณ อุทยานราชภักดิ์ อำเภอหัวหิน จังหวัดประจวบคีรีขันธ์ "
            , con2: "โดยมีการจัดทำกิจกรรมต่าง ๆ มากมาย รวมทั้งช่วงสำคัญของงาน การยิงเลเซอร์ไปยังภูเขาหลังอุทยานราชภักดิ์ เป็นลายเส้นพระบรมฉายาลักษณ์ของในหลวง ร.9 และสัญลักษณ์ต่าง ๆ ที่สื่อถึงพระองค์ โดยภายในงานมีผู้เข้าร่วมกว่า 1,000 คน ซึ่งทางคณะผู้จัดงานได้ปฏิบัติตามมาตรการป้องกันการแพร่ระบาดของเชื้อไวรัสโควิด-19 "
            , con3: "https://www.facebook.com/watch/?v=1111586559652170 ", con4: "", con5:""
        },
        {
            slug: 'sur',name: "ต้องรอด Up for Thai", picA: "../../../../assets/img/alpaca/surA.png", picB: "../../../../assets/img/alpaca/surB.png", picC: "../../../../assets/img/alpaca/surC.png", con1: "คุณอัพ (กุลทรัพย์ วัฒนผล หรือ อัพ VGB) อดีตนักกีฬาอีสปอร์ตที่เป็นผู้ป่วยติดเชื้อโควิด-19 และได้มีการกักตัวระหว่างรอตรวจอาการหลายวันจนโคม่า จนได้เสียชีวิตลงในเวลาต่อมา จากการสูญเสียในวันนั้นกลายเป็นแรงบันดาลใจ ของกลุ่มผู้มีอุดมการณ์ในการลุกขึ้นมามีส่วนร่วมในการแก้ไขปัญหาร่วมกันเพื่อทำอะไรบางอย่างในยามวิกฤติ \n โครงการจิตอาสา ต้องรอด Up for Thai มีเป้าหมายในการช่วยเหลือผู้ที่ได้รับความเดือดร้อน และต้องการรับการสนับสนุนเครื่องอุปโภคบริโภค รวมถึงการช่วยเหลือด้านการบริการข้อมูล เพื่อช่วยลดทอนภาระของหน่วยงานภาครัฐ และช่วยให้คนไทยสามารถเข้าถึงข้อมูลได้อย่างรวดเร็วและมีประสิทธิภาพ"
            , con2: "บริษัท อัลปากา มีเดีย แอนด์ เทคโนโลยี โซลูชั่นส์ จํากัด ได้มีการจัดทำคลิปวิดีโอเรื่องราวของโครงการต้องรอดและนำไปเผยแพร่ในช่องทาง Facebook page:  The Publisher ", con3: "https://www.facebook.com/ThePublisherth/videos/4499055346781230 ", con4: "", con5:""
        },
        {
            slug: 'misshuahin',name: "เพลงคิดถึงหัวหิน", picA: "../../../../assets/img/alpaca/mhuahinA.jpg", picB: "../../../../assets/img/alpaca/mhuahinB.jpg", picC: "../../../../assets/img/alpaca/mhuahinC.jpg", con1: "บริษัท อัลปากา มีเดีย แอนด์ เทคโนโลยี โซลูชั่นส์ จํากัด ร่วมกับ SEED Thailand, สมาคมนักเรียนไทย-จีน และเทศบาลเมืองหัวหิน จัดทำมิวสิควิดีโอเพลง “คิดถึง...หัวหิน” ขับร้องโดย มาร์ค ธนพนธ์ สิงหพันธุ์ เยาวชนหัวหิน โดยเนื้อเพลงบอกเล่าความรู้สึกถึงการ \“อยากชวนคนที่คุณรักไปเที่ยวหัวหินด้วยกัน\" "
            , con2: "โดยจัดทำเพื่อประชาสัมพันธ์ และสร้างความเชื่อมั่นให้กับนักท่องเที่ยวทั้งชาวไทย และชาวต่างชาติ โดยเฉพาะอย่างยิ่งนักท่องเที่ยวชาวจีน เพื่อบอกให้รู้ว่าหัวหินเป็นจุดหมายปลายทางในการท่องเที่ยวที่มีความพร้อมของระบบสาธารณสุข และความปลอดภัยอย่างรอบด้าน และเพื่อปลุกบรรยากาศการท่องเที่ยวและเศรษฐกิจเมืองหัวหินให้กลับมาครึกครื้นอีกครั้ง", con3: "", con4: "https://www.youtube.com/watch?v=27hCBsCf3vQ ", con5:""
        },

    ]


    ngOnInit(): void {
        this.title.setTitle("รายละเอียดผลงาน")
        this.name = this.route.snapshot.paramMap.get('slug');
        console.log(this.name);

        this.currentProject = this.projects.filter(x => {
            return x.slug == this.name
        })[0]
        console.log(this.currentProject)
        console.log(this.currentProject.picB)

        if (this.currentProject.con3 == ''){console.log("con3 pass condition")}

    }

}
