import { Component, OnInit } from '@angular/core';
import { Customer } from 'src/app/customer.model';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  customer: Customer = {
    name: '',
    email: '',
    contact: '',
    description: '',
    company: '',
  };

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }


  submit() {
    console.log(this.customer);
    var body = { customer: this.customer }
    this.http.post('https://alpacathailand.com/api/createContact.php', body).subscribe(res => {
      console.log(res);
    })
  
    this.customer = {
      name: '',
      email: '',
      contact: '',
      description: '',
      company: '',
    };
  }

}
