<app-navbar-one></app-navbar-one>
<div class="main-banner-area-six">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <span>การบริการของเรา</span>
                            <h1>ทีมโปรดักชั่น</h1>
                            <p>ความสำเร็จของโปรดักชั่นขึ้นอยู่กับการใส่ใจในรายละเอียดของงานนั้น ๆ </p>
                            <div class="banner-btn">
                                <a routerLink="/contact" class="default-btn">เริ่มกับเรา</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 pr-0">
                        <div class="banner-image">
                            <img src="assets/img/alpaca/services/services1/PD.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</div>


<section class="digital-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="digital-image">
                    <img src="assets/img/alpaca/s4-2.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="digital-content">
                    <h3> <span>ทีมโปรดักชั่น</span></h3>
                    <div class="bar"></div>
                    <p>ด้วยทีมงานที่จะสร้างสรรค์ สื่อวิดีโอให้ตรงกับผลิตภัณฑ์และตอบโจทย์ลูกค้า ที่คุณต้องการ สามารถเลือกได้ตามงบประมาณที่วางไว้ ช่วยส่งเสริมภาพลักษณ์หรืออธิบายเกี่ยวกับแบรนด์ได้ครบถ้วน และสร้างความน่าเชื่อถือด้วยงานที่มีคุณภาพให้กับสินค้าและบริการของคุณ</p>
                    <ul class="digital-list">
                        <li><i class="flaticon-check"></i> มิวสิควิดีโอ</li>
                        <li><i class="flaticon-check"></i> วิดีโอโฆษณา</li>
                        <li><i class="flaticon-check"></i> วิดีโอไวรัล</li>
                        <li><i class="flaticon-check"></i> วิดีโอโปรโมทอิเว้นท์</li>
                        <li><i class="flaticon-check"></i> โมชั่นกราฟิก</li>
                        <li><i class="flaticon-check"></i> ถ่ายทอดสด</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="tab-section pb-100">
    <div class="container">
        <div class="section-title">
            <h2><span>วีดีโอครบวงจร</span> </h2>
            <p>สร้างสรรค์ตัวตนผ่านการสร้างสรรค์วีดีโอคุณภาพโดยทีมมืออาชีพ</p>
            <div class="bar"></div>
        </div>
        <div class="tab schedule-list-tab">
            <ul class="tabs">
                <li><a href="#"><span><i class="flaticon-analysis"></i> วีดีโอโฆษณา</span></a></li>
                <li><a href="#"><span><i class="flaticon-optimize"></i> วีดีโอไวรัล</span></a></li>
                <li><a href="#"><span><i class="flaticon-software"></i> การนำเสนออีเวนต์แบบวีดีโอ</span></a></li>
                <li><a href="#"><span><i class="flaticon-internet"></i> โมชั่นกราฟฟิก</span></a></li>
                <li><a href="#"><span><i class="flaticon-data"></i> ถ่ายทอดสด</span></a></li>
            </ul>
            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-content">
                                <h2>วีดีโอโฆษณา</h2>
                                <p>ดึงดูดกลุ่มเป้าหมายของคุณด้วยวีดีโอโฆษณาที่มีคุณภาพ</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>สร้างสรรค์</h3>
                                    <p>เราสามารถสร้างสรรค์วีดีโอที่โดดเด่นและแตกต่างให้กับคุณ</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>แพลตฟอร์มที่หลากหลาย</h3>
                                    <p>การวางแผนแก้ปัญหาอย่างรัดกุมในหลากหลายมิติ เพราะปัญหาที่เกิดขึ้น มีทั้งสถานการณ์เริ่มต้น ฉุกเฉิน วิกฤติ</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ตอบโจทย์กลุ่มเป้าหมาย</h3>
                                    <p>สามารถจัดทำวีดีโอที่ตอบโจทย์กับกลุ่มเป้าหมาย </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/alpaca/s4-3.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-content">
                                <h2>วีดีโอไวรัล</h2>
                                <p>เราติดตามเทรนเสมอ เพื่อสร้างสรรค์วีดีโอที่มีคุณภาพให้กับคุณ</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ตามเทรน</h3>
                                    <p>เราสามารถจับเทรนและนำมาสร้างสรรค์เป็นวีดีโอที่มีคุณภาพเพื่อคุณ</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>อย่างไม่น่าเชื่อ</h3>
                                    <p>เราสามารถรังสรรค์ผลงานได้มากกว่าคนที่คุณคาดหวัง</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>มีระดับ</h3>
                                    <p>เราสามารถออกแบบวีดีโอให้คุณได้อย่างมีระดับตามที่คุณต้องการ</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/alpaca/s4-4.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-content">
                                <h2>การนำเสนออีเวนต์แบบวีดีโอ</h2>
                                <p>ให้ผู้คนรู้จักคุณผ่านวีดีโออีเวนต์ที่มีคุณภาพ</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ทางการ</h3>
                                    <p>เราสามารถจัดรูปแบบวีดีโอได้อย่างเป็นทางการดูน่าเชื่อถือ</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>มืออาชีพ</h3>
                                    <p>จัดรูปแบบการนำเสนออีเวนต์แบบวีดีโอได้อย่างมืออาชีพ</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ยกระดับ</h3>
                                    <p>สามารถช่วยเพิ่มระดับให้กับบริษัทของคุณ</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/alpaca/s4-5.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-content">
                                <h2>โมชั่นกราฟฟิก</h2>
                                <p>ให้โมชั่นกราฟฟิกช่วยเพิ่มระดับธุรกิจของคุณ</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>มีการเคลื่อนไหว</h3>
                                    <p>เราสามารถจัดกราฟฟิกแบบเคลื่อนไหวที่น่าสนใจ</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>เรื่องราวเล่าเรื่อง</h3>
                                    <p>เราสามารถสื่อสารข้อมูลผ่านการสร้างสรรค์รูปภาพ</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>สร้างสรรค์</h3>
                                    <p>สร้างสรรค์งานได้หลากหลายรูปแบบ</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/alpaca/s4-6.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="tab-content">
                                <h2>ถ่ายทอดสด</h2>
                                <p>เราสามารถจัดการงานสัมมนาออนไลน์ผ่านทางแพลตฟอร์มต่าง ๆ</p>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>คมชัด</h3>
                                    <p>เราช่วยให้การถ่ายทอดสดของคุณภาพคมชัดไม่มีสะดุด</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>มุมกล้อง</h3>
                                    <p>สามารถหามุมกล้องที่เหมาะสมช่วยให้วีดีโอดูน่าสนใจมากยิ่งขึ้น</p>
                                </div>
                                <div class="tab-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-tick"></i>
                                    </div>
                                    <h3>ชำนาญ</h3>
                                    <p>เราสามารถจัดการถ่ายทอดสดให้กับคุณได้อย่างชำนาญ</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/alpaca/s4-7.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="pricing-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Pricing</span> Plan</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-pricing">
                    <div class="pricing-header">
                        <h3>Basic</h3>
                        <p>Business Up</p>
                    </div>
                    <div class="price">
                        <sup>$</sup>29<sub>/mo</sub>
                    </div>
                    <ul class="pricing-list">
                        <li><i class="flaticon-checked"></i> 10 GB Hosting </li>
                        <li><i class="flaticon-checked"></i> 2 Unique Users </li>
                        <li><i class="flaticon-checked"></i> 12 GB Capacity</li>
                        <li><i class="flaticon-cancel"></i> Any Where Access</li>
                        <li><i class="flaticon-cancel"></i> Weekly Backup</li>
                        <li><i class="flaticon-cancel"></i> Support 24/Hour</li>
                    </ul>
                    <div class="price-btn">
                        <a routerLink="/" class="default-btn">Buy Now</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-pricing">
                    <div class="pricing-header">
                        <h3>Standard</h3>
                        <p>Business Up</p>
                    </div>
                    <div class="price">
                        <sup>$</sup>79<sub>/mo</sub>
                    </div>
                    <ul class="pricing-list">
                        <li><i class="flaticon-checked"></i> Visitor Info</li>
                        <li><i class="flaticon-checked"></i> Quick Responses</li>
                        <li><i class="flaticon-checked"></i> 12 GB Capacity</li>
                        <li><i class="flaticon-checked"></i> Any Where Access</li>
                        <li><i class="flaticon-cancel"></i> Weekly Backup</li>
                        <li><i class="flaticon-cancel"></i> Support 24/Hour</li>
                    </ul>
                    <div class="price-btn">
                        <a routerLink="/" class="default-btn">Buy Now</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-pricing">
                    <div class="pricing-header">
                        <h3>Premium</h3>
                        <p>Business Up</p>
                    </div>
                    <div class="price">
                        <sup>$</sup>99<sub>/mo</sub>
                    </div>
                    <ul class="pricing-list">
                        <li><i class="flaticon-checked"></i> 10 GB Hosting </li>
                        <li><i class="flaticon-checked"></i> 2 Unique Users </li>
                        <li><i class="flaticon-checked"></i> 12 GB Capacity</li>
                        <li><i class="flaticon-checked"></i> Any Where Access</li>
                        <li><i class="flaticon-checked"></i> Weekly Backup</li>
                        <li><i class="flaticon-cancel"></i> Support 24/Hour</li>
                    </ul>
                    <div class="price-btn">
                        <a routerLink="/" class="default-btn">Buy Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section> -->